.icon_container {
  display: grid;
  place-items: center;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid transparent;
}

.lowest {
  border-color: #afc4d7;
}

.low {
  border-color: #cfd8dc;
}

.medium {
  border-color: #ffcd1e;
}

.high {
  border-color: #ec407a;
}

.highest {
  border-color: #ff7d7d;
}
