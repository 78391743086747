.production_wrapper {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 8px;
}

.checkbox {
  position: absolute;
  top: 50%;
  left: -24px;
  display: flex;
  justify-content: flex-start;
  height: 90%;
  width: 100%;
  transform: translate(5px, -50%);
  transition: opacity 0.15s, transform 0.15s;
  opacity: 0;
  pointer-events: none;
  transition-duration: 0.15s !important;

  &[data-is-checkbox-visible='true'] {
    pointer-events: all;
    opacity: 1;
    transform: translate(0, -50%);
  }
}
