.wrapper {
  display: flex;
  align-items: center;
}

.inner_container {
  overflow: 'hidden';
  display: flex;
  justify-content: center;
}
