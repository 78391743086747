.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  &_opacity {
    opacity: 0.6;
  }
}
