.checkbox {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s, transform 0.15s;
  transform: translateX(5px);

  &[data-show-checkbox='true'] {
    pointer-events: all;
    opacity: 1;
    transform: translateX(0);
  }
}
