.divider {
  width: 100%;
  border-bottom: 1px solid #e2e6f7;
  margin: 12px 0;
}

.option_text {
  font-family: Montserrat, serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
}
