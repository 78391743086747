.container {
  background-color: #ebf0f5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  &:global(.S) {
    width: 34px;
    height: 34px;
    border-radius: 9px;
  }
  &:global(.M) {
    width: 40px;
    height: 40px;
    border-radius: 11px;
  }
  &:global(.L) {
    width: 48px;
    height: 48px;
    border-radius: 12px;
  }
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.abbreviation {
  font-weight: 500;
  line-height: 1;

  &:global(.S) {
    font-size: 14px;
  }
  &:global(.M) {
    font-size: 16px;
  }
  &:global(.L) {
    font-size: 18px;
  }
}
