.container {
  color: #141414;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 2px solid #0851fe;
  margin-bottom: 32px;
}

.image {
  width: 48px;
  height: 48px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 8px;
}

.text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
