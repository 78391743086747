.lock_container {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.lock_wrapper {
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  background-color: #9eadc1;
  transition: all 200ms;

  &[data-is-visible='false'] {
    opacity: 0;
  }
}
