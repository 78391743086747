.other_users_container {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background-color: white !important;
  color: #0851fe;
  margin-left: -8px;
  margin-top: -2px;
  border-radius: 50% !important;
  font-weight: 500;
  padding: 0 !important;
  min-width: 30px;
  border: 1.5px solid #0851fe !important;
  transition: background-color 200ms, color 200ms !important;
  &:hover {
    background-color: #0851fe !important;
    color: white !important;
    cursor: pointer;
  }
}

.item_image {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  color: #878b92;
  font-size: 14px;
  font-weight: 500;
  border: unset;
}

.item_title {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.menu_container {
  :global(.MuiPaper-root) {
    max-width: 190px;
    width: 100%;
    padding: 6px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 2px 5px 32px 0px rgba(0, 0, 0, 0.06);
  }
  .body_container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    .item_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
    }
  }
}
