:root {
  --main-font: 'Montserrat', sans-serif;
  --background-main-color: #ecf4fc;
  --background-secondary-color: rgba(252, 252, 252, 0.36);
  --background-section-color: #ffffff;
  --primary-text-color: #000000;
  --secondary-text-color: #6f767e;
  --accent-color: #4b8bfa;
  --main-transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  --subTitle-color: #1a1d1f;

  /* button */
  --primary-button-bgc: rgba(254, 254, 254, 0.65);
  --secondary-button-bgc: rgba(2, 2, 2, 0.65);
  --warning-button-color: #dd2b1d;
  --primary-button-text-color: #000000;
  --secondary-button-text-color: #ffffff;
  --button-box-shadow: 3.87046px 0px 27.0932px rgba(255, 255, 255, 0.1), 1.54818px 11.6114px 19.3523px rgba(167, 167, 167, 0.15),
    inset 0.774091px 9.28909px 68.12px 1.16114px rgba(0, 0, 0, 0.68), inset 6.96682px 1.54818px 37.1564px rgba(0, 0, 0, 0.55);
  --main-border-radius: 10px;

  /* input */
  --input-border: 2px solid #272b30;
  --main-input-width: 253px;
  --main-input-height: 40px;

  /* --------------- new UI ------------ */
  --border_color: #595959;
  --hover_color: #74b2ff;
  --hover_color_light: #91c3ff;

  --border_radius: 14px;

  --transition: all 100ms ease-in-out;
}

/* breakpoints */
$mobile: 575px;
$tablet: 768px;
$tablet-max: 1055px;
$desktop: 1679px;
