.avatar {
  width: 30px;
  height: 30px;
  border: 1.5px solid #fff;
  background-color: #dceefe;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  cursor: unset;

  &[data-is-empty='true'] {
    background-color: #dee2e6;
  }

  &[data-is-active='true'],
  &[data-with-hover='true']:hover {
    border-color: #7894ff;
    cursor: pointer;
  }

  &.selected {
    border-color: #7894ff;
  }
}

.empty_mode {
  background-color: #e0e0e0;

  svg {
    fill: #fff;
  }
}
