.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: #dceefe;
  padding: 38px 27px 38px 20px;

  .text_cta {
    color: #141414;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 16px;
  }
  .buttons {
    display: flex;
    gap: 16px;
  }
  .button {
    height: 41px;
  }
  .button_outlined {
    svg path {
      stroke: #2969ff;
    }
  }
}
