.workflow_container {
  position: relative;
  display: flex;
  align-items: center;
  width: 280px;
  height: 80px;
  background-color: white;
  border-radius: 12px;
  transition: 200ms;
  box-shadow: 2px 5px 32px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  padding: 8px 14px 8px 18px;
  &:hover {
    border-color: #0851fe;
  }

  &[data-is-empty='true'] {
    padding: unset;
    pointer-events: none;
    cursor: default;
  }

  &[data-is-updated='true'] {
    &::after {
      content: '';
      width: 100%;
      height: 50px;
      position: absolute;
      top: -4px;
      left: 0;
      z-index: -1;
      border-radius: 12px;
      background-color: white;
      border: 1px solid #8ab6e0;
    }
  }
}

.icon {
  position: absolute;
  left: -12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #dfe2e4;
  border-radius: 50%;

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke-width: 2;
    }
  }
}

.workflow_name_container {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 4px;
}

.workflow_name {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
  max-width: calc(100% - 40px);
}

.version {
  @extend .workflow_name;
  padding: 0px 4px 2px 4px;
  background-color: #ebeef0;
  border-radius: 4px;
}

.details_container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.details {
  font-size: 14px;
  color: #141414;
  display: flex;
  gap: 3px;
  height: 18px;

  .value {
    font-weight: 500;
  }
}

.icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #b5d3ef;
}

.text {
  color: #204366;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
}

.inner_block {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
}

.empty_slot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: #dceefe;
  cursor: default;
  &[data-highlighted='true'] {
    border: 1px solid #7b78ff;
  }
}

.handle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -5px;
  width: 16px !important;
  height: 16px !important;
  background-color: #e4e7e9 !important;
  border: 1px solid #878b92 !important;

  &_hide {
    opacity: 0;
  }
}

.to_do,
.reopened {
  border: 1px solid #8ab6e0;
}

.blocked,
.stopped {
  border: 1px solid #fc7070;
}

.in_progress,
.on_hold {
  border: 1px solid #7894ff;
}

.done,
.from_stock,
.canceled {
  border: 1px solid #a6c919;
}
