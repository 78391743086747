.MuiButtonBase-root {
  box-shadow: none;
  text-transform: none;
  border-radius: 8px;
  &,
  * {
    transition-property: all;
    transition-duration: 0ms;
    transition-delay: 0ms;
  }
  &:hover {
    text-transform: none;
  }
  & .MuiButton-startIcon {
    margin-left: 0px;
  }
  & .MuiButton-endIcon {
  }

  // ------ shape & size styles -------
  &.square,
  &.round {
    padding: unset;
    min-width: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }

  // ------ sizes styles --------
  &.MuiButton-sizeXL {
    font-size: 14px;
    padding: 13px 16px;
    &.square {
      padding: 0px;
      font-size: 14px;
      width: 49px;
      height: 49px;
      border-radius: 12px;
    }
    &.round {
      padding: 0px;
      font-size: 14px;
      width: 49px;
      height: 49px;
      border-radius: 100%;
    }
  }
  &.MuiButton-sizeL,
  &.MuiButton-sizeLarge {
    font-size: 14px;
    padding: 11px 24px;
    &.square {
      padding: 0px;
      font-size: 12px;
      width: 45.5px;
      height: 45.5px;
      border-radius: 8px;
    }
    &.round {
      padding: 0px;
      font-size: 12px;
      width: 45.5px;
      height: 45.5px;
      border-radius: 100%;
    }
  }
  &.MuiButton-sizeM,
  &.MuiButton-sizeMedium {
    font-size: 14px;
    padding: 9px 16px;
    &.square {
      padding: 0px;
      width: 41px;
      height: 41px;
      border-radius: 8px;
    }
    &.round {
      padding: 0px;
      width: 41px;
      height: 41px;
      border-radius: 100%;
    }
  }
  &.MuiButton-sizeS,
  &.MuiButton-sizeSmall {
    font-size: 12px;
    padding: 7px 16px;
    &.square {
      padding: 0px;
      font-size: 10px;
      width: 34px;
      height: 34px;
      border-radius: 8px;
      svg {
        width: 12px;
        height: 12px;
      }
    }
    &.round {
      padding: 0px;
      font-size: 10px;
      width: 34px;
      height: 34px;
      border-radius: 100%;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
  &.MuiButton-sizeXS {
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 6px;
    svg {
      width: 14px;
      height: 14px;
    }
    &.square {
      padding: 0px;
      font-size: 7px;
      width: 30px;
      height: 30px;
      border-radius: 6px;
      svg {
        width: 12px;
        height: 12px;
      }
    }
    &.round {
      padding: 0px;
      font-size: 7px;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
  &.MuiButton-sizeXXS {
    font-size: 12px;
    padding: 3px 7px;
    border-radius: 6px;
    svg {
      width: 12px;
      height: 12px;
    }
    &.square {
      padding: 0px;
      font-size: 5px;
      width: 26px;
      height: 26px;
      border-radius: 6px;
      svg {
        width: 10px;
        height: 10px;
      }
    }
    &.round {
      padding: 0px;
      font-size: 5px;
      width: 26px;
      height: 26px;
      border-radius: 100%;
      svg {
        width: 10px;
        height: 10px;
      }
    }
  }

  // ------ icon styles ------
  &.stroke_icon {
    stroke: currentColor;
    fill: transparent;
    svg * {
      stroke: currentColor;
      fill: transparent;
    }
    &:hover,
    &:active,
    &.Mui-Disabled {
      svg * {
        stroke: currentColor;
        fill: transparent;
      }
    }
  }
  &.fill_icon {
    stroke: transparent;
    fill: currentColor;
    svg * {
      stroke: transparent;
      fill: currentColor;
    }
    &:hover,
    &:active,
    &.Mui-Disabled {
      svg * {
        stroke: transparent;
        fill: currentColor;
      }
    }
  }
  &.custom_icon {
    // no styles --- should be specified in icon itself
  }
}

.MuiButton-text {
  border: 1px solid transparent;
}

// ------------------------------ LOADING STATE MIXIN ---------------------------- //
@mixin loading($spinnerColor) {
  &.MuiLoadingButton-loading {
    color: transparent !important;
    > svg,
    > span {
      opacity: 0;
    }
    .MuiLoadingButton-loadingIndicator .MuiCircularProgress-root * {
      opacity: 1;
      stroke: $spinnerColor !important;
      fill: transparent;
    }
  }
}

// ------------------------------ ---------------------- ------------------------------ //
// ------------------------------ BUTTON VARIANTS STYLES ------------------------------ //
// ------------------------------ ---------------------- ------------------------------ //

.MuiButton-containedPrimary {
  color: #ffffff;
  background-color: #437bff;
  border: 1px solid #437bff;
  box-shadow: 3px 5px 15px #437bff20;
  transition: color 0.2s, background-color 0.2s, border 0.2s, box-shadow 0.2s;
  &:hover {
    color: #ffffff;
    background-color: #2969ff;
    border: 1px solid #2969ff;
    box-shadow: 3px 5px 15px rgba(67, 123, 255, 0.2);
  }
  &:active {
    color: #ffffff;
    background-color: #0851fe;
    border: 1px solid #0851fe;
  }
  &.Mui-disabled {
    pointer-events: none;
    box-shadow: none;
    color: #677a8c;
    border: 1px solid #dce6ed;
    background-color: #dce6ed;
  }
  @include loading(#437bff);
}

// Contained Secondary Button Styles
.MuiButton-containedSecondary {
  color: #ffffff;
  background-color: #8397aa;
  border: 1px solid #8397aa;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: #778b9d;
    border: 1px solid #778b9d;
  }
  &:active {
    background-color: #647d93;
    border: 1px solid #647d93;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #677a8c;
    border: 1px solid #dce6ed;
    background-color: #dce6ed;
  }
  @include loading(#778b9d);
}

// Contained Black Button Styles
.MuiButton-containedBlack {
  color: #ffffff;
  background-color: #000;
  border: 1px solid #000;
  box-shadow: none;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: #323232;
    border: 1px solid #323232;
  }
  &:active {
    background-color: #242424;
    border: 1px solid #242424;
    stroke: #fff;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #677a8c;
    border: 1px solid #dce6ed;
    background-color: #dce6ed;
  }
  @include loading(#141414);
}

// Contained White Button Styles
.MuiButton-containedWhite {
  color: #141414;
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: none;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: #fbfbfb;
    border: 1px solid #fbfbfb;
  }
  &:active {
    background-color: #fbfbfb;
    border: 1px solid #fbfbfb;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #677a8c;
    border: 1px solid #dce6ed;
    background-color: #dce6ed;
  }
  @include loading(#5f5f5f);
}

// Contained Error Button Styles
.MuiButton-containedError {
  color: #fff;
  background-color: #ff5a5a;
  border: 1px solid #ff5a5a;
  box-shadow: 3px 5px 15px rgba(255, 55, 55, 0.2);
  transition: color 0.2s, background-color 0.2s, border 0.2s, box-shadow 0.2s;
  &:hover {
    background-color: #ff4e4e;
    border: 1px solid #ff4e4e;
    box-shadow: 3px 5px 15px rgba(255, 55, 55, 0.2);
  }
  &:active {
    background-color: #ff3737;
    border: 1px solid #ff3737;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #677a8c;
    border: 1px solid #dce6ed;
    background-color: #dce6ed;
  }
  @include loading(#ff5a5a);
}

// Contained Success Button Styles
.MuiButton-containedSuccess {
  color: #141414;
  background-color: #c3df50;
  border: 1px solid #c3df50;
  box-shadow: 2px 5px 32px #00000006;
  transition: color 0.2s, background-color 0.2s, border 0.2s, box-shadow 0.2s;
  &:hover {
    background-color: #b7d737;
    border: 1px solid #b7d737;
  }
  &:active {
    background-color: #a7c91c;
    border: 1px solid #a7c91c;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #677a8c;
    border: 1px solid #ced1d4;
    background-color: #ced1d4;
  }
  @include loading(#a0c11f);
}

// Contained Info Button Styles
.MuiButton-containedInfo {
  color: #141414;
  background-color: #c0d3ff;
  border: 1px solid #c0d3ff;
  box-shadow: none;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: #a7c1ff;
    border: 1px solid #a7c1ff;
  }
  &:active {
    background-color: #c0d3ff;
    border: 1px solid #c0d3ff;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #677a8c;
    border: 1px solid #dce6ed;
    background-color: #dce6ed;
  }
  @include loading(#a1b7ea);
}

// Contained Info Light Button Styles
.MuiButton-containedInfo_light {
  color: #677a8c;
  background-color: #eef1fc;
  border: 1px solid #eef1fc;
  box-shadow: none;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: #e7edf1;
    border: 1px solid #e7edf1;
    color: #141414;
    box-shadow: none;
  }
  &:active {
    background-color: #dfe2e4;
    border: 1px solid #dfe2e4;
    color: #141414;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #c3c7ca;
    border: 1px solid #e7edf1;
    background-color: #e7edf1;
  }
  @include loading(#677a8c);
}

// Contained Warning Button Styles
.MuiButton-containedWarning {
  color: #fff;
  background-color: #f8a586;
  border: 1px solid #f8a586;
  box-shadow: none;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: #f69572;
    border: 1px solid #f69572;
  }
  &:active {
    background-color: #f48c65;
    border: 1px solid #f48c65;
    color: #fff;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #677a8c;
    border: 1px solid #dce6ed;
    background-color: #dce6ed;
  }
  @include loading(#f8a586);
}

// Outlined Primary Button Styles
.MuiButton-outlinedPrimary {
  color: #0851fe;
  background-color: transparent;
  border: 1px solid #0851fe;
  stroke: #0851fe;
  transition: color 0.2s, background-color 0.2s, border 0.2s, stroke 0.2s;
  &:hover {
    background-color: transparent;
    border: 1px solid #6a96fe;
    color: #6a96fe;
  }
  &:active {
    background-color: transparent;
    border: 1px solid #0851fe;
    color: #0851fe;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #677a8c;
    background-color: transparent;
    border: 1px solid #ced1d4;
    stroke: #ced1d4;
  }
  @include loading(#437bff);
}

// Outlined Secondary Button Styles
.MuiButton-outlinedSecondary {
  color: #778b9d;
  background-color: transparent;
  border: 1px solid #bcc4e3;
  stroke: #778b9d;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: transparent;
    border: 1px solid #778b9d;
    color: #141414;
  }
  &:active {
    border: 1px solid #0851fe;
    color: #141414;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #95a3b0;
    background-color: transparent;
    border: 1px solid #ced1d4;
  }
  @include loading(#95a3b0);
}

// Outlined Black Button Styles
.MuiButton-outlinedBlack {
  color: #141414;
  background-color: transparent;
  border: 1px solid #141414;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    border: 1px solid #323232;
    color: #323232;
  }
  &:active {
    background-color: transparent;
    border: 1px solid #0851fe;
    color: #323232;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #677a8c;
    background-color: transparent;
    border: 1px solid #ced1d4;
    stroke: #ced1d4;
  }
  @include loading(#677a8c);
}

// Outlined Error Button Styles
.MuiButton-outlinedError {
  color: #ff5a5a;
  background-color: transparent;
  border: 1px solid #ff5a5a;
  transition: color 0.2s, background-color 0.2s, border 0.2s, stroke 0.2s;
  &:hover {
    border: 1px solid #ff4e4e;
    color: #ff4e4e;
  }
  &:active {
    background-color: transparent;
    border: 1px solid #ff3737;
    stroke: #ff3737;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #677a8c;
    background-color: transparent;
    border: 1px solid #ced1d4;
  }
  @include loading(#ff5a5a);
}

// Outlined Info Button Styles
.MuiButton-outlinedInfo {
  color: #789ef5;
  background-color: transparent;
  border: 1px solid #789ef5;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    border: 1px solid #5482ed;
    color: #5482ed;
  }
  &:active {
    background-color: transparent;
    border: 1px solid #416cd0;
    color: #416cd0;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #adb7bf;
    background-color: transparent;
    border: 1px solid #ced1d4;
  }
  @include loading(#789ef5);
}

// Outlined Info Light Button Styles
.MuiButton-outlinedInfo_light {
  color: #8ea0c9;
  background-color: transparent;
  border: 1px solid #a9bae3;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    border: 1px solid #8a98b9;
    color: #7788b0;
  }
  &:active {
    background-color: transparent;
    border: 1px solid #6c7ca1;
    color: #6c7ca1;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #adb7bf;
    background-color: transparent;
    border: 1px solid #ced1d4;
  }
  @include loading(#8ea0c9);
}

// Text Info Button Styles
.MuiButton-textInfo {
  color: #789ef5;
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: transparent;
    border: 1px solid transparent;
    color: #416cd0;
  }
  &:active {
    background-color: transparent;
    border: 1px solid transparent;
    color: #5482ed;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #b7b7b7;
    background-color: transparent;
    border: 1px solid transparent;
  }
  @include loading(#789ef5);
}

// Text Primary Button Styles
.MuiButton-textPrimary {
  color: #0851fe;
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: transparent;
    border: 1px solid transparent;
    color: #3773ff;
  }
  &:active {
    background-color: transparent;
    border: 1px solid transparent;
    color: #0049f3;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #b7b7b7;
    background-color: transparent;
    border: 1px solid transparent;
  }
  @include loading(#437bff);
}

// Text Secondary Button Styles
.MuiButton-textSecondary {
  color: #8397aa;
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: transparent;
    border: 1px solid transparent;
    color: #2969ff;
  }
  &:active {
    background-color: transparent;
    border: 1px solid transparent;
    color: #0049f3;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #b7b7b7;
    background-color: transparent;
    border: 1px solid transparent;
  }
  @include loading(#b7b7b7);
}

// Text Black Button Styles
.MuiButton-textBlack {
  color: #141414;
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: transparent;
    border: 1px solid transparent;
    color: #0851fe;
  }
  &:active {
    background-color: transparent;
    border: 1px solid transparent;
    color: #2164ff;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #b7b7b7;
    background-color: transparent;
    border: 1px solid transparent;
  }
  @include loading(#b7b7b7);
}

// Text Error Button Styles
.MuiButton-textError {
  color: #ff5a5a;
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: transparent;
    border: 1px solid transparent;
    color: #ff4e4e;
  }
  &:active {
    background-color: transparent;
    border: 1px solid transparent;
    color: #ff3737;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #b7b7b7;
    background-color: transparent;
    border: 1px solid transparent;
  }
  @include loading(#ff5a5a);
}

// Text Background Info Button Styles
.MuiButton-text_bgInfo {
  color: #789ef5;
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: #e7edf1;
    border: 1px solid #e7edf1;
    color: #5482ed;
  }
  &:active {
    background-color: #dde3e7;
    border: 1px solid #dde3e7;
    color: #416cd0;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #b7b7b7;
    background-color: #e7edf1;
    border: 1px solid #e7edf1;
  }
  @include loading(#789ef5);
}

// Text Background Primary Button Styles
.MuiButton-text_bgPrimary {
  color: #0851fe;
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: #e7edf1;
    border: 1px solid #e7edf1;
    color: #2567ff;
  }
  &:active {
    background-color: #dde3e7;
    border: 1px solid #dde3e7;
    color: #0049f3;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #b7b7b7;
    background-color: #e7edf1;
    border: 1px solid #e7edf1;
  }
  @include loading(#437bff);
}

// Text Background Secondary Button Styles
.MuiButton-text_bgSecondary {
  color: #8397aa;
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: #e7edf1;
    border: 1px solid #e7edf1;
    color: #8397aa;
  }
  &:active {
    background-color: #dde3e7;
    border: 1px solid #dde3e7;
    color: #8397aa;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #b7b7b7;
    background-color: #e7edf1;
    border: 1px solid #e7edf1;
  }
  @include loading(#b7b7b7);
}

// Text Background Black Button Styles
.MuiButton-text_bgBlack {
  color: #141414;
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: #e7edf1;
    border: 1px solid #e7edf1;
    color: #141414;
  }
  &:active {
    background-color: #dde3e7;
    border: 1px solid #dde3e7;
    color: #141414;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #b7b7b7;
    background-color: #e7edf1;
    border: 1px solid #e7edf1;
  }
  @include loading(#b7b7b7);
}

// Text Background Error Button Styles
.MuiButton-text_bgError {
  color: #ff5a5a;
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  transition: color 0.2s, background-color 0.2s, border 0.2s;
  &:hover {
    background-color: #e7edf1;
    border: 1px solid #e7edf1;
    color: #ff4e4e;
  }
  &:active {
    background-color: #dde3e7;
    border: 1px solid #dde3e7;
    color: #ff3737;
  }
  &.Mui-disabled {
    pointer-events: none;
    color: #b7b7b7;
    background-color: #e7edf1;
    border: 1px solid #e7edf1;
  }
  @include loading(#ff5a5a);
}
