.search_input {
  padding-left: 34px !important;
}

.apply_btn {
  border: none;
  color: #829db6;
  padding: 4px 16px;
  min-height: 31px;
  border-radius: 8px;
  font-weight: 500;
  background-color: #e2eef7;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.search_icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.scrollable {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-height: 350px;
  overflow: scroll;
}

.infinite_scroll {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
