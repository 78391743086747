.react-datepicker__day--in-range,
.react-datepicker__day--in-range:hover {
  background-color: #fff;
  color: #000;
  border-radius: 0;
}
.react-datepicker__day--range-start,
.react-datepicker__day--range-start:hover,
.react-datepicker__day--range-end:hover,
.react-datepicker__day--range-end {
  background-color: black;
  color: #fff;
  border-radius: 0;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__day--selected:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: black;
  color: #fff;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-start:hover {
  border-radius: 6px 0px 0px 6px;
}

.react-datepicker__day--range-end,
.react-datepicker__day--range-end:hover {
  border-radius: 0 6px 6px 0;
}

.modal-picker {
  position: absolute;
  background: #fbfbfb;
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  width: 445px;
  user-select: none;
  z-index: 10;
  left: 0;
  top: calc(100% + 10px);
}
.modal-picker .react-datepicker__header {
  background-color: transparent;
  border-bottom: none;
}
.modal-picker .react-datepicker__day,
.modal-picker .react-datepicker__day-name {
  width: 3rem;
  line-height: 3rem;
  font-size: 14px;
  margin: 0;
}
.modal-picker .react-datepicker__week {
  margin: 0.166rem 0;
}
.modal-picker .react-datepicker__day-name {
  font-weight: 500;
}
.modal-picker .react-datepicker__month-container {
  float: none;
}
.react-datepicker__day--keyboard-selected {
  background-color: transparent;
  color: black;
}
.react-datepicker__day--keyboard-selected.react-datepicker__day--range-end {
  background-color: black;
  color: #fff;
}
.react-datepicker__day--range-start.react-datepicker__day--range-end {
  border-radius: 6px;
}
