.MuiPickersPopper-root[role='tooltip'] {
  .MuiPickersPopper-paper {
    background: #fff !important;
    border-radius: 12px !important;
    .MuiDateRangeCalendar-root {
      background: #fff;
      box-shadow: 0px 6px 24px 0px rgba(54, 55, 70, 0.08);
      backdrop-filter: blur(5px);
      border-radius: 12px;
      padding: 16px 0;
    }

    .MuiTypography-subtitle1 {
      color: #141414;
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
    }

    .MuiPickersArrowSwitcher-button {
      display: flex;
      padding: 4px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 5px;
      background: #dae3ff !important;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04) !important;
      }
    }

    .MuiPickersArrowSwitcher-root {
      padding: 0 16px 8px 16px;
    }

    .MuiDayCalendar-weekDayLabel {
      color: #141414;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
    }

    .MuiDateRangePickerDay-day {
      color: #141414;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
    }

    .MuiDateRangePickerDay-day.Mui-selected {
      border-radius: 6px;
      background: #437bff;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
    }

    .MuiDateRangePickerDay-rangeIntervalDayPreview {
      &:first-of-type {
        border-top-left-radius: unset !important;
        border-bottom-left-radius: unset !important;
      }
      &:last-of-type {
        border-top-right-radius: unset !important;
        border-bottom-right-radius: unset !important;
      }
    }

    .MuiDateRangePickerDay-root {
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
      &:first-of-type {
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
      }
      &:last-of-type {
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
      }
    }

    .MuiDateRangePickerDay-rangeIntervalDayHighlight {
      background: rgba(234, 238, 252, 0.7);
    }

    .MuiDateRangePickerDay-day:not(.Mui-selected) {
      border-radius: 6px;
    }
  }
}

// Date time picker
.MuiPickersPopper-root[role='dialog'] {
  .MuiPaper-root {
    background: #fff !important;
    padding: 16px;
    border-radius: 12px !important;
    box-shadow: 0px 6px 24px 0px rgba(54, 55, 70, 0.08);
    .MuiDateCalendar-root {
      width: 265px;
      margin-left: 0;
      margin-right: 24px;
      max-height: 285px;
      margin-bottom: 24px;
    }
    .MuiPickersLayout-contentWrapper > div {
      margin: 0;
    }
    .MuiPickersLayout-contentWrapper .MuiDivider-root {
      border-right-width: 0;
      border-bottom-width: 0;
    }
    .MuiPickersLayout-root {
      display: flex;
      flex-direction: column;
    }
    .MuiDayCalendar-slideTransition {
      width: 267px;
      min-height: 220px;
    }
    .MuiPickersCalendarHeader-root {
      margin-bottom: 16px;
      margin-top: 0;
      padding-left: unset;
      padding-right: unset;
    }
    .MuiPickersCalendarHeader-switchViewButton {
      display: none;
    }
    .MuiPickersCalendarHeader-label {
      color: #050505;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    .MuiPickersArrowSwitcher-root {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }
    .MuiPickersArrowSwitcher-spacer {
      width: 16px;
    }
    .MuiIconButton-edgeEnd {
      display: flex;
      padding: 4px;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: #dae3ff;
      &:hover {
        will-change: background-color;
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
    .MuiIconButton-edgeStart {
      display: flex;
      padding: 4px;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: #dae3ff;
      &:hover {
        will-change: background-color;
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
    .MuiDayCalendar-header > span {
      color: #141414;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
    }
    .MuiPickersDay-root {
      height: 32px;
      color: #050505;
      font-weight: 500;
      font-size: 14px;
      line-height: 157%;
    }
    .MuiDayCalendar-monthContainer .Mui-selected {
      border-radius: 6px;
      background: #437bff;
      color: #fff;
      &:hover {
        will-change: background-color;
        background-color: rgb(5, 56, 177);
      }
    }
    .MuiList-root {
      width: 43px;
    }
    .MuiMultiSectionDigitalClock-root {
      border-radius: 9px;
      background: rgba(245, 248, 255, 0.7);
      height: 275px;
      &.Mui-selected {
        border-radius: 6px;
        background: #437bff;
        color: #fff;
      }
      & > li {
        width: 35px;
        height: 32px;
        color: #050505;
        font-weight: 500;
        font-size: 14px;
        line-height: 157%;
      }
      &:not(:first-of-type) {
        border-left: unset;
        margin: 0;
      }
    }
    .MuiMultiSectionDigitalClock-root .Mui-selected {
      border-radius: 6px;
      background: #437bff;
      color: #fff;
      &:hover {
        will-change: background-color;
        background-color: rgb(5, 56, 177);
      }
    }
    .MuiPickersLayout-actionBar {
      justify-content: center;
      padding: 0;
      & > button {
        width: 362px;
      }
    }
  }
}
