.container {
  position: relative;
  width: 100%;
}

.text_field {
  width: 100%;
}

.buttons {
  position: absolute;
  right: 0;
  bottom: 0;
  transition: opacity 0.15s, transform 0.15s;
  transform: translate(0, 110%);
  z-index: 1000;
}
