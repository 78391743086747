@import '../../styles/vars.scss';

.skeleton_wrapper {
  padding: 20px 40px;
}

.skeleton {
  &_tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  &_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  span {
    transform: none;
  }
}

.page_title {
  padding: 20px 40px;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 42px */
}

@media (max-width: $tablet-max) {
  .page_title {
    font-size: 18px;
    font-weight: 600;
    line-height: 150%; /* 27px */
  }
}

.row {
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 200ms;

  &[data-with-checkbox='true'] {
    flex-direction: row;
    align-items: center;
  }

  &:hover {
    background-color: #fafbff;
  }
}

@media (max-width: $tablet-max) {
  .row {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding: 4px 0;

    &[data-with-checkbox='true'] {
      gap: 16px;
      flex-direction: column;
    }
  }
}

.section {
  padding: 24px 32px 20px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px solid #d5e4f2;
  }
}

@media (max-width: $tablet-max) {
  .section {
    gap: 12px;
  }
}

.row_title {
  gap: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%; /* 21px */
}

@media (max-width: $tablet-max) {
  .row_title {
    font-weight: 500;
  }
}

.right_col {
  max-width: 400px;
  width: 100%;
}

@media (max-width: $tablet-max) {
  .right_col {
    max-width: none;
  }
}

.right_col_group {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.row_description {
  color: #141414;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
}

.title_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
}

.logo {
  background-color: #e8f5b3;
}

.icon {
  min-width: 16px;
  cursor: pointer;

  g * {
    transition: stroke 0.3s;
  }

  &:hover {
    g * {
      stroke: #0851fe;
    }
  }
}

.icon_container {
  display: inline-block;
  padding-left: 6px;
  vertical-align: text-top;
}

.info_icon {
  circle {
    fill: #141414;
  }

  path {
    stroke: #141414;
  }
}

.spinner_container {
  display: grid;
  place-items: center;
  z-index: 2;

  .spinner {
    position: relative;
    left: unset;
    top: unset;
    transform: none;
  }
}

.checkbox_container {
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    white-space: nowrap;
  }
}

.tab_label {
  color: #131313;

  &[data-active-tab='general'][data-tab='general'],
  &[data-active-tab='production'][data-tab='production'],
  &[data-active-tab='mobile'][data-tab='mobile'] {
    font-weight: 600;
  }
}

.tabs {
  padding: 0 40px;
}

.tab {
  padding: 0 8px;
  margin-bottom: 8px;
  height: 38px;
  min-height: 38px;
  transition: all 200ms !important;

  &:not(:last-child) {
    margin-right: 24px;
  }

  &:hover {
    background-color: #fafbff;
  }
}

.tooltip {
  max-width: 500px;
}
