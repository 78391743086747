.input {
  width: 100%;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #bcc4e3;
  padding: 0 12px;
  transition: all 200ms;
  outline: none;

  &:focus {
    border-color: #0851fe;
  }

  &:disabled {
    background-color: transparent;
    opacity: 0.3;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 24px;
  height: 21px;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: #d5e4f2;
  }

  &[data-active='true'] {
    div {
      &:first-child {
        width: 8px;
        margin-right: 4px;
      }

      background-color: #292d32;
    }

    &::after {
      content: '';
      position: absolute;
      top: 5px;
      right: 5px;
      width: 4px;
      height: 4px;
      background-color: #0851fe;
      border-radius: 50%;
    }
  }
}

.line {
  height: 1px;
  border-radius: 99px;
  background-color: #5e7285;

  &:first-child {
    width: 12px;
  }

  &:nth-child(2) {
    width: 8px;
  }

  &:last-child {
    width: 4px;
  }
}

.popover {
  width: 222px;
  margin-top: 4px;
  padding: 8px 12px;
}
