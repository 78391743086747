.title {
  color: #141414;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.button {
  color: #0851fe;
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
  &:hover {
    color: #4f7ae0;
  }
}
