.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg circle {
    color: #0851fe;
    stroke-linecap: round;
  }
}
