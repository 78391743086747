.modal {
  width: 100%;
  max-width: 500px !important;
}
.subtitle {
  p {
    font-size: 20px !important;
  }
}
.close_icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.modal_description {
  margin-top: 8px;
  color: #141414;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.input_container {
  margin-bottom: 20px;
}

.info {
  color: #141414;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.footer {
  padding: 0 24px 24px 24px !important;
}

.tooltip_title {
  display: flex;
  align-items: center;
  gap: 6px;

  svg {
    flex-shrink: 0;
  }
}
