.modal {
  width: 500px;
  padding: 24px;
}

.subtitle {
  align-self: unset;
}

.modal_header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 0 24px 0;
}

.footer {
  display: flex;
  gap: 16px;
}

.button {
  height: 50px;
  flex-basis: 50%;
}

.persone_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  background-color: #d7eeff;
  border-radius: 50%;
}

.users_input_option {
  padding: 7px 8px !important;
}

.avatar {
  height: 24px;
  width: 24px;
  font-size: 12px;
}

.text_input_option {
  padding: 4px 8px !important;
}
