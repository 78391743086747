.warning_container {
  display: flex;
  flex-direction: row;
  gap: 4px;
  .warning_icon {
    svg {
      stroke: #ff6464;
    }
  }
  .warning_title {
    color: #141414;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
  }
}
