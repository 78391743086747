.text_field {
  // Light theme styles
  :global(.MuiInputBase-root) {
    background-color: transparent;

    &:global(.Mui-disabled) {
      fieldset {
        border: 2px solid #f3f3f3 !important;
      }
    }
    &:global(.Mui-error) {
      fieldset {
        border: 1px solid #ff6464 !important;
      }
    }
  }

  :global(&.Mui-disabled) {
    pointer-events: none;
  }
}
