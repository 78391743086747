@import '../../../../styles/vars.scss';

.menu_container {
  width: 16px;
  height: 100%;
  transition: width 0.2s ease-in-out;
  position: relative;
  z-index: 3;

  &[data-is-expanded='true'] {
    width: 240px;
  }

  @media (max-width: $tablet-max) {
    position: fixed;
    &[data-is-mobile-menu-opened='true'] {
      min-width: 100vw;
      width: 100%;
    }
    &[data-is-mobile-menu-opened='false'] {
      width: 0;
    }
  }
}

.menu_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  transition: width 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  height: inherit;
  background: #ffffff;
  border-right: 1px solid #eaeaee;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.02);

  display: flex;
  flex-direction: column;

  &[data-is-open='true'] {
    width: 240px;
  }
  &[data-is-expanded='true'] {
    box-shadow: none;
  }

  @media (max-width: $tablet-max) {
    &[data-is-mobile-menu-opened='true'] {
      width: 100%;
    }
    &[data-is-mobile-menu-opened='false'] {
      width: 0;
    }
  }
}

.arrow,
.rotated_arrow {
  &:hover,
  &:hover * {
    stroke: #545454;
    color: #545454;
  }
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  transition: padding 0.2s ease-in-out;
  padding: 24px 8px 24px 8px;

  @media (max-width: $tablet-max) {
    min-height: min-content;
    padding: 12px 16px;
  }
  @media (min-width: $tablet-max) {
    &[data-is-open='true'] {
      padding: 24px 30px 24px 30px;
    }
  }

  &_title_container {
    display: flex;
    flex-direction: row;
    gap: 4px;
    padding: 4px 12px;
    width: 108px;
    background: #ebeef0;
    border-radius: 6px;
  }

  &_title {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #000000;
    transition: all 200ms ease-in-out;
    white-space: nowrap;
    opacity: 1;
    overflow: visible;
  }
}

.switch_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -12px;
  top: 33px;
  width: 24px;
  height: 24px;
  opacity: 1;
  min-height: 24px;
  min-width: 24px;
  border-radius: 50%;
  z-index: 10;
  background-color: white;
  border: 1.5px solid;
  border-color: #e7edf1;
  transition: 0.2s border-color, 0.2s opacity;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    cursor: pointer;
    border-color: #0851fe;
  }

  &[data-hide-switch='true'] {
    opacity: 0;
    pointer-events: none;
  }

  @media (max-width: $tablet-max) {
    display: none;
  }
}

.switch_icon {
  transition: 0.5s;
  path {
    stroke: #141414;
  }

  &[data-isopen='false'] {
    transform: rotateY(180deg);
  }
}

.dash {
  margin: 8px 30px;
  height: 1px;
  background-color: #eeeef0;

  @media (max-width: $tablet-max) {
    margin: 6px 16px;
    height: 1px;
    background-color: #eeeef0;
  }
}

.body {
  flex-grow: 1;
  height: 100%;
  position: relative;
  outline: none;
}

.item_list {
  display: flex;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
}

.item_group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.item_with_icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.animated_logo {
  padding-left: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: 114px;
  cursor: pointer;

  @media (max-width: $tablet-max) {
    .animated_logo {
      display: none;
    }
  }
}

.logo_text {
  font-size: 27px;
  font-weight: 600;
  color: #0851fe;
}

.logo_icon {
  min-height: 30px;
  min-width: 30px;
}

.extended_hover_zone {
  position: absolute;
  top: 0;
  right: -11px;
  height: 100%;
  width: 11px;
  background-color: transparent;
}
