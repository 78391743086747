@mixin section {
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.05);

  display: flex;
  justify-content: space-between;
  gap: 16px;
  // > button {
  //   padding-left: 30px;
  //   padding-right: 30px;
  // }
}

.section {
  @include section();
}

.section_without_shadow {
  @include section();
  box-shadow: none;
}

.with_border_top {
  border-top: 1px solid #eff5f9;
}
