.spinner_container {
  position: relative;
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #d7eeff;
  margin-left: 0px;
  border: 1px solid #ffffff;
}

.spinner_margin {
  margin-left: -10px;
}
