.button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;

  &[data-pressable='true'] {
    cursor: pointer;
  }
}
