:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #0288d1;
  --toastify-color-success: #bcf4de;
  --toastify-color-warning: #fcdcc0;
  --toastify-color-error: #ffd7d7;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: Montserrat, sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  /* Used only for colored theme */
  --toastify-text-color-info: #000000;
  --toastify-text-color-success: #000000;
  --toastify-text-color-warning: #000000;
  --toastify-text-color-error: #000000;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  /* Used when no type is provided
  toast("**hello**") */
  --toastify-color-progress-light: grey;

  /* Used when no type is provided */
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-body {
  align-items: flex-start;
  > div:last-child {
    margin-top: 2px;
  }
}
.Toastify__toast {
  border-radius: 8px;
  font-size: 14px;
  min-height: unset;
  user-select: none;
  width: fit-content;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}
