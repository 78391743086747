.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.label {
  font-weight: 500;
}

.input {
  width: 100%;

  div {
    border-radius: 6px;
    padding: 0px 6px;

    div {
      margin-left: -6px !important;
    }
  }
}
