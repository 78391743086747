@import '../../styles/vars.scss';

.select_wrapper {
  position: relative;
}

.tag {
  font-size: 14px;
}

.option_list {
  :global(.MuiMenu-paper) {
    margin-top: 8px;
  }

  :global(.MuiPaper-root) {
    padding: 8px 14px 8px 8px;
    border-radius: 8px;
    box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.1);
  }
}

.tooltip {
  & > div {
    margin: 7px 0px !important;
    padding: 8px !important;
    max-width: none;
  }
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  cursor: pointer;

  &:not(:first-child) {
    padding-top: 8px;
  }
}

.icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 21px;
  width: 21px;
  border-radius: 6px;
  transition: 200ms;
}

.title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  color: #141414;
  transition: 200ms;
}

.to_do,
.reopened {
  transition: background-color 200ms;
  .icon_container {
    background-color: transparent;

    * {
      stroke: #50677d;
    }
  }

  .title {
    color: #141414;
  }

  &:hover {
    .icon_container {
      background-color: #8ab6e0;

      * {
        stroke: #e8f4ff;
      }
    }

    .title {
      color: #50677d;
    }
  }
}

.blocked,
.stopped {
  transition: background-color 200ms;
  .icon_container {
    background-color: transparent;

    * {
      stroke: #fc7070;
    }
  }

  &:hover {
    .icon_container {
      background-color: #fc7070;

      * {
        stroke: #fff;
      }
    }

    .title {
      color: #ff4e4e;
    }
  }
}

.in_progress,
.on_hold {
  transition: background-color 200ms;
  .icon_container {
    background-color: transparent;

    * {
      stroke: #7894ff;
    }
  }

  &:hover {
    .icon_container {
      background-color: #7894ff;
      * {
        stroke: #fff;
      }
    }

    .title {
      color: #446dff;
    }
  }
}

.done,
.from_stock,
.canceled {
  transition: background-color 200ms;
  .icon_container {
    background-color: transparent;

    * {
      stroke: #a6c919;
    }
  }

  &:hover {
    .icon_container {
      background-color: #c3df50;

      * {
        stroke: #fff;
      }
    }

    .title {
      color: #98b915;
    }
  }
}

.active {
  cursor: pointer;
  color: #3ed296;
  border-color: #3ed296;
  transition: background-color 200ms;
  * {
    stroke: #3ed296;
  }

  ul &:hover {
    background-color: transparent;
    .icon_container {
      background-color: #bcf4de;
      * {
        stroke: #3ed296;
      }
    }
    .title {
      color: #3ed296;
    }
  }

  &:hover {
    background-color: #3ed296;
    * {
      stroke: #fff;
    }
    color: #fff;
  }
  &:focus-within {
    background-color: #3ed296;
    * {
      stroke: #fff;
    }
    color: #fff;
  }
}

.inactive {
  cursor: pointer;
  color: #e55656;
  border-color: #e55656;
  transition: background-color 200ms;
  .general_tag {
    background-color: #e55656;
    * {
      stroke: #fff;
    }
    color: #fff;
  }
  * {
    stroke: #e55656;
  }

  ul &:hover {
    background-color: transparent;
    .icon_container {
      background-color: #ffd8d8;
      * {
        stroke: #e55656;
      }
    }
    .title {
      color: #e55656;
    }
  }

  &:hover {
    background-color: #e55656;
    * {
      stroke: #fff;
    }
    color: #fff;
  }
  &:focus-within {
    background-color: #e55656;
    * {
      stroke: #fff;
    }
    color: #fff;
  }
}

.pending {
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
