.body {
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  .icon_with_title {
    display: flex;
    gap: 5px;
    align-items: center;
    .title {
      font-size: 14px;
    }
  }
}
