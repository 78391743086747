body {
  &::-webkit-scrollbar,
  *::-webkit-scrollbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(3px);
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb,
  *::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: #b3b3b3;
  }

  &::-webkit-scrollbar-track,
  *::-webkit-scrollbar-track {
    background-color: transparent;
    box-shadow: none;
  }

  &::-webkit-scrollbar:start,
  *::-webkit-scrollbar:start {
    height: 10px;
    display: block;
  }
}
