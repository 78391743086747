@import './vars.scss';

.root {
  min-height: inherit;
}

.layout {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}
.col-4 {
  padding: 0 20px;
  flex: 0 0 33.33%;
  max-width: 33.33%;
}
.col-8 {
  padding: 0 20px;
  flex: 0 0 66.66%;
  max-width: 66.66%;
}

.tableHead {
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.tableHead > div {
  display: flex;
  align-items: center;
}
.tableHeadTitle {
  padding: 0 12px 0 24px;
  line-height: 28px;
}
.tableHeadTitle:before {
  left: 0;
  top: 2px;
}
.tableHeadSearch + i {
  position: relative;
}
.tableHeadSearch + i:before {
  position: absolute;
  bottom: 100%;
  content: '';
  width: 14px;
  height: 14px;
  background-image: url('../icons/svg/search.svg');
}
.tableHeadSearch {
  padding: 3px 10px 3px 25px;
  font-size: 13px;
  line-height: 1.38;
  height: 33px;
  border: 2px solid #f8f8f8;
  border-radius: 8px;
  width: 100%;
  background-color: #fefefe;
  background-image: url('../icons/svg/search.svg');
  background-repeat: no-repeat;
  background-position: 7px 8px;
  background-size: 16px;
  /* max-width: var(--main-input-width); */
}

.tableSelect > div {
  height: 26px;
  border-color: #f4f4f4;
  border-radius: 5px;
}
.tableSelect > div > div {
  padding: 0 10px;
  font-size: 12px;
  align-items: center;
}
.tableSelect > div > div + div {
  padding: 0;
}
.tableSelect > div > div + div ul > li {
  padding: 5px;
  font-size: 12px;
  align-items: center;
}
.tableSelect > div > div:after {
  right: 2px;
  bottom: 8px;
}
.tableSelect.disabled > div {
  border-color: #f4f4f4;
  opacity: 0.5;
  cursor: not-allowed;
}
.table-btn-wrap {
  display: flex;
  justify-content: center;
}
.table-icon-btn {
  border: 1px solid rgba(170, 170, 170, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  background-color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
}

.table-icon-btn:hover {
  background-color: black;
}
.table-icon-btn:hover svg {
  fill: #fff;
}
.dash {
  width: 12px;
  height: 2px;
  background: rgba(0, 0, 0, 0.35);
  border-radius: 2px;
  display: inline-block;
}
.cellWrap-disabled {
  color: rgba(0, 0, 0, 0.35);
}
.cellWrap-center {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accessDenied {
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 100%;
}
.accessDenied span {
  min-width: 300px;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  background: linear-gradient(
    91.1deg,
    rgba(255, 229, 194, 0.65) 0.47%,
    rgba(249, 207, 183, 0.65) 46.95%,
    rgba(255, 180, 180, 0.65) 97.47%,
    rgba(255, 180, 180, 0.65) 97.47%
  );
}

.root {
  font-family: var(--main-font) !important;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: -0.01em;
  min-height: 100vh;
  color: #141414;
  font-family: var(--main-font);
  background-color: #f1f5f9 !important;
}

.root ~ div {
  font-family: var(--main-font) !important;
}

@media screen and (max-width: 1280px) {
  body {
    font-size: 12px;
    line-height: 1.2;
  }
}

body {
  padding-right: 0 !important;
  overflow: unset !important;
}
