.modal {
  border-radius: 8px !important;
  padding: 16px;
  margin: 0;
}

.body_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.header_container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  .title_container {
    display: flex;
    flex-direction: column;
    .title {
      color: #141414;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
    }
  }
}

.button {
  height: 32px;
}
