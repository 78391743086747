@import '../../styles/mixins/flex.scss';
@import '../../styles/vars.scss';

.underlined_text {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  text-decoration-line: none;
  font-size: 14px;
}

.container {
  @include flex(row, center, center);
  width: 100%;
  background-color: white;
}

.wrapper {
  display: flex;
  min-height: clamp(100vh, 100vh, 1000px);
  width: min(100%, 1800px);
}

// LEFT COLUMN

.left_column {
  @include flex(row, center);
  flex: 1;
  padding-top: 40px;

  & > div {
    @include flex(column);
    max-width: 482px;
    width: 100%;
  }
}
.logo_conainer {
  display: flex;
  width: min-content;
}

.content_wrapper {
  @include flex(row, center, center);
  flex: 1;
}

// RIGHT COLUMN

.right_column {
  flex: 1;
  padding: 16px 16px 16px 0;
  color: #fff;
}

.login_image {
  @include flex(row, center, center);
  position: relative;
  height: 100%;
  padding: 40px;
  background-color: #437bff;
  border-radius: 12px;
  overflow: hidden;
}

.text {
  @include flex(column);
  z-index: 1;
  gap: 8px;
  max-width: 400px;

  h1 {
    text-align: center;
    font-size: 58px;
    line-height: 130%;
  }

  p {
    text-align: center;
    font-size: 14px;
  }
}

.background_sign {
  position: absolute;
  top: 60px;
  right: -40px;
}

.emoji {
  background-color: #6f99ff;
}

@media (max-width: $tablet-max) {
  .container {
    min-height: inherit;
    min-width: inherit;
  }

  .wrapper {
    @include flex(column);
    gap: 16px;
  }

  .right_column {
    display: none;
  }

  .sign_up_right_column {
    display: flex;
    justify-content: center;
    flex: 0;
    padding: 0;
    align-items: flex-start;
    padding: 0 16px;
  }

  .content_wrapper {
    flex: inherit;
  }

  .left_column {
    flex: 1;
    padding-top: 0px;

    & > div {
      @include flex(column, flex-start);
      width: 100%;
      max-width: 100%;
      padding: 64px 16px 0px 16px;
    }
  }

  .left_column_header {
    @include flex(column, center);
    gap: 16px;
  }

  .left_column {
    .left_column_header_sign_up {
      @include flex(column, flex-end);
      flex: 1;
    }
  }

  .login_image {
    padding: 16px;
    width: 100%;
    & > svg {
      display: none;
    }
  }
}
