.button {
  min-width: 162px;
  height: 32px;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  transition: all 200ms;

  &:hover {
    opacity: 0.7;
  }

  &[data-variant='primary'] {
    background-color: #2969ff;
  }

  &[data-variant='secondary'] {
    background-color: #829db6;
  }
}

.input {
  display: none;
}
