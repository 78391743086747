@import '../../../../styles/vars.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 40px 5px 30px;
  border-bottom: 1px solid #eaeaee;
  background-color: #ffffff;

  @media (max-width: $tablet-max) {
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 12px 16px;
    background-color: #ffffff;
  }
}

.icons_container {
  display: flex;
  gap: 16px;
}

.chevron_icon_container {
  display: flex;
  align-items: center;
  transition: transform 0.2s;

  &[data-is-menu-open='true'] {
    transform: rotate(180deg);
  }

  @media (max-width: $tablet-max) {
    display: none;
  }
}

.icon_rotate {
  transform: rotate(180deg);
}

.burger_menu {
  display: none;
  place-items: center;
  border: none;
  width: 35px;
  height: 35px;
  padding: 0;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #5e7285;

  &[data-is-active='true'] {
    border: 1px solid #0851fe;
  }

  @media (max-width: $tablet-max) {
    display: grid;
  }
}

.user_container {
  display: flex;
  gap: 12px;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;
  padding: 5px;
  transition: background-color 250ms;

  &:hover {
    background-color: #eaeff9;
  }
}

.user {
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0 0;
  gap: 10px;

  &_info {
    display: flex;
    flex-direction: column;
    padding-top: 1px;
  }

  &_name {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #141414;
  }

  @media (max-width: $tablet-max) {
    &_info {
      display: none;
    }
  }
}

.mobile_qr {
  min-width: 0 !important;
  padding: 0 !important;

  > span {
    margin-right: 0 !important;
  }

  svg path,
  svg rect {
    stroke: #141414 !important;
    transition: stroke 0.2s ease-in-out;
  }

  &:hover {
    svg path,
    svg rect {
      stroke: #0851fe !important;
    }
  }
}

.logo_wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: $tablet-max) {
    display: none;
  }
}

.right_col {
  display: flex;
  gap: 16px;
}
