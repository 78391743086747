@import '../../styles/vars.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
  padding: 16px;
}

@media (max-width: $tablet) {
  .container {
    padding: 0;
  }
}

.background_panel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #437bff;
  border-radius: 12px;
  overflow: hidden;
}

@media (max-width: $tablet) {
  .background_panel {
    border-radius: 0;
  }
}

.background_sign {
  position: absolute;
  top: 60px;
  right: -40px;
  z-index: 0;
}

.company_selector {
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  z-index: 1;
  padding: 48px;
  border-radius: 16px;
}

@media (max-width: $tablet-max) {
  .company_selector {
    padding: 24px;
  }
}

@media (max-width: $tablet) {
  .company_selector {
    max-width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 0;
    padding: 32px 16px;
  }
}

.cta_text {
  margin-bottom: 16px;
  color: #141414;
  font-size: 24px;
  font-weight: 600;
  line-height: 150%; /* 36px */
}

@media (max-width: $tablet-max) {
  .cta_text {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 150%; /* 27px */
  }
}

.skeleton_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.companies_container {
  padding: 8px;
  border-radius: 16px;
  border: 1px solid #eaeaea;
  max-height: 450px;
  overflow-y: scroll;
}

@media (max-width: $tablet) {
  .companies_container {
    margin-bottom: 24px;
  }
}

@media (max-width: $mobile) {
  .companies_container {
    max-height: 300px;
    margin-bottom: 24px;
  }
}

.company_item {
  padding: 16px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 100%;

  &:hover {
    background: #f6f9fc;
  }
}

@media (max-width: $tablet-max) {
  .company_item {
    font-size: 14px;
    font-weight: 500;
  }
}

.logo {
  border-radius: 11px;
  width: 40px;
  height: 40px;
  background-color: #f1f5f9;
  overflow: hidden;
}

.logout_button {
  position: absolute;
  top: calc(100% + 28px); /* 28px - it's gap between .company_selector & .logout_button */
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  border: none;
  background-color: transparent;
}

@media (max-width: $tablet) {
  .logout_button {
    position: initial;
    transform: translateX(0);
    align-self: center;
    color: #141414;
    font-weight: 500;
  }
}

.company_logo {
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
}

.hesh_logo {
  display: inline-block;
  margin-bottom: 32px;
}

@media (max-width: $tablet-max) {
  .hesh_logo {
    margin-bottom: 16px;
  }
}

.company_selector_wrapper {
  position: relative;
  z-index: 10;
  max-width: 500px;
  width: 100%;
}

@media (max-width: $tablet) {
  .company_selector_wrapper {
    max-width: 100%;
    height: 100vh;
  }
}
