.conatiner {
  padding: 0 40px 100px;
}

.heading {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  padding: 12px;
  background-color: white;
  margin-bottom: 16px;
  box-shadow: 2px 5px 32px 0px rgba(0, 0, 0, 0.04);
}

.activate_product_container {
  display: flex;
  flex-direction: column;
  .text {
    font-weight: 400;
    font-size: 14px;
  }
  .related_product {
    cursor: pointer;
    color: #141414;
    font-weight: 700;
    text-decoration: underline;
    &:hover {
      color: #0851fe;
    }
  }
}

.switch {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #141414;
  justify-content: flex-end;
  cursor: default;
  &_title {
    color: #141414;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
  &_disabled {
    opacity: 0.4;
  }
}

.publish_btn {
  height: 39px;
}

.row {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}

.sub_header {
  display: grid;
  gap: 16px;
  align-items: center;
  grid-template-columns: minmax(1px, auto) 120px;
}

.product_name {
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
}

.placeholder_name {
  font-weight: 400;
  font-size: 22px;
  line-height: 130%;
}

.info_container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.skeleton_row {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
}
