.subtitle {
  display: flex;
  gap: 8px;
  align-self: center;
  align-items: center;
  .text {
    display: inline-flex;
    font-weight: 400;
    align-items: center;
    word-break: break-word;
    color: #141414;
  }
}
