.container_with_image {
  display: flex;
  flex-wrap: wrap;
  width: 40px;
  height: 40px;
  background: #cfd8dc;
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(7.5px);
  border-radius: 100px;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: 100%;
  background-size: 100%;
  position: relative;
  .svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
