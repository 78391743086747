.ag-root-wrapper {
  font-family: 'Montserrat';
  border: unset !important;
  border-top: 1px solid #e7edf1 !important;
  border-radius: 0 !important;
  background: #fff;

  --ag-border-color: #e7edf1;
  --ag-header-background-color: #fafbfd;
  --ag-input-focus-border-color: #0851fe;
}

.ag-theme-quartz {
  --ag-active-color: #0851fe !important;
  --ag-input-focus-box-shadow: unset !important;
}

/*------ table header ------*/

.ag-header-icon:hover {
  background-color: #d5e4f2 !important;
  box-shadow: 0 0 0 4px #d5e4f2 !important;
}

/* .ag-header-cell {
  height: 40px !important;
}

.ag-header-row {
  height: 40px !important;
  top: 40px !important;
}

.ag-header-row-column-group {
  top: 0 !important;
}

.ag-header {
  height: 80px !important;
  min-height: 80px !important;
} */

/*------ side button (side bar) ------*/

input.ag-input-field-input.ag-text-field-input {
  color: #141414 !important;
}

.ag-side-button-label {
  font-family: 'Montserrat' !important;
}

.ag-side-button {
  height: 100%;
}

.ag-side-button-button {
  height: 100%;
  padding-top: 18px !important;
  justify-content: flex-start !important;
}

/*------ side bar ------*/

.ag-column-select-checkbox {
  margin-right: 6px !important;
}

.ag-column-select-header {
  border-bottom: unset !important;
  padding: 16px 16px 12px 16px !important;
  height: unset !important;
}

.ag-column-group-icons:hover {
  background-color: #d5e4f2 !important;
  box-shadow: 0 0 0 4px #d5e4f2 !important;
}

.ag-drag-handle {
  margin-right: 6px !important;
  transform: rotate(90deg) translateX(1px);
}

.ag-row:hover [data-is-visible='false'] .lock_wrapper {
  opacity: 0.5 !important;
}

.ag-text-field-input-wrapper {
  &::before {
    display: none !important;
  }
  > input {
    padding: 0 12px !important;
    color: #878b92 !important;
    font-size: 14px !important;
    border-radius: 8px !important;
  }
}

.ag-column-panel-column-select {
  background-color: #fff !important;
}

.ag-column-select-list {
  background-color: #fff !important;
}

.ag-column-select-virtual-list-item {
  padding: 0 16px !important;
}

.ag-tool-panel-wrapper {
  border-radius: 12px 0 0 12px;
  box-shadow: -2px 2px 10px 0px rgba(0, 0, 0, 0.1);
}

.ag-react-container {
  width: 100%;
  height: 100%;
}

.ag-floating-filter-body {
  align-items: center;
}

.ag-cell {
  line-height: 3.3;
}

.ag-menu {
  --ag-grid-size: 4px;

  font-family: 'Montserrat' !important;
  border-radius: 8px !important;
  padding: var(--ag-grid-size) !important;
  background-color: #fff !important;
  border: none !important;
}

.ag-menu-option {
  color: #141414;
  font-weight: 400 !important;
}

.ag-menu-option-part {
  padding: 6px !important;
}

.ag-menu-option.ag-menu-option-active {
  background-color: #f1f5f9;
}

.ag-menu-option [data-ref='eIcon'] {
  border-radius: 6px 0 0 6px !important;
  padding-right: 0 !important;
}

.ag-menu-option [data-ref='ePopupPointer'] {
  border-radius: 0 6px 6px 0;
}

/*======= Scrolling in the table =======*/
.ag-body-vertical-scroll {
  width: 8px !important;
  max-width: 8px !important;
  min-width: 8px !important;
}

.ag-body-vertical-scroll-viewport,
.ag-body-vertical-scroll-container {
  width: 8px !important;
  max-width: 8px !important;
  min-width: 8px !important;
}

.ag-body-horizontal-scroll {
  height: 8px !important;
  max-height: 8px !important;
  min-height: 8px !important;
}

.ag-horizontal-right-spacer {
  width: 0px !important;
  max-width: 0px !important;
  min-width: 0px !important;
}

.ag-body-horizontal-scroll-viewport,
.ag-body-horizontal-scroll-container {
  height: 8px !important;
  max-height: 8px !important;
  min-height: 8px !important;
}

.ag-root ::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(3px);
  width: 8px;
  height: 8px;
}

.ag-root ::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: #b3b3b3;
}

.ag-root ::-webkit-scrollbar-track {
  background-color: transparent;
  box-shadow: none;
}

.ag-root ::-webkit-scrollbar:start {
  height: 8px;
  display: block;
}
