.assigned_users_container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0px;
  height: 100%;

  .avatar_container {
    width: 32px;

    &:hover {
      z-index: 10;
    }
  }

  .other_users_container {
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    background-color: white;
    color: #0851fe;
    margin-left: -8px;
    margin-top: -2px;
    border-radius: 50%;
    font-weight: 500;
    border: 1.5px solid #0851fe;
    transition: background-color 200ms, color 200ms;
    &:hover {
      background-color: #0851fe;
      color: white;
      cursor: pointer;
    }
  }
}
