@import '../../../../styles/vars.scss';

@mixin base($color) {
  svg path,
  svg rect,
  svg circle {
    stroke: $color;
  }
  .icon_container {
    border-color: $color;
  }
}

@mixin hover($color) {
  svg path,
  svg rect,
  svg circle {
    stroke: #ffffff;
    fill: #ffffff;
  }
  .icon_container {
    border-color: $color;
    background-color: $color;
  }
  .title {
    color: $color;
  }
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  &:not(:first-child) {
    padding-top: 6px;
  }

  @media (max-width: $tablet) {
    &:not(:first-child) {
      padding-top: 8px;
    }
  }
}

.icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 5px;
  border: 1px solid transparent;

  @media (max-width: $tablet) {
    padding: 1px;
    height: 16px;
    width: 16px;
    > svg {
      height: 10px;
      width: 10px;
    }
  }
}

.title {
  font-size: 14px;
  color: #5a5a5a;

  @media (max-width: $tablet) {
    font-size: 13px;
  }
}

.container_highest {
  @include base(#ff7d7d);
  &:hover {
    @include hover(#ff7d7d);
  }
}
.container_high {
  @include base(#f99891);
  &:hover {
    @include hover(#f99891);
  }
}
.container_medium {
  @include base(#ffb74d);
  &:hover {
    @include hover(#ffb74d);
  }
}
.container_low {
  @include base(#878b92);
  &:hover {
    @include hover(#878b92);
  }
}
.container_lowest {
  @include base(#afc4d7);
  &:hover {
    @include hover(#afc4d7);
  }
}
