@import '../../../styles/vars.scss';

.container {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #bcc4e3;
  padding: 7.5px 12px;
  height: 40px;
  line-height: 1;
  cursor: pointer;
  gap: 8px;
  user-select: none;
  transition: background-color 0.15s;

  .chevron_icon {
    transform: rotate(90deg);
    transition: transform 0.1s;
    &.open {
      transform: rotate(-90deg);
    }
  }

  &:hover {
    border-color: #0851fe;
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
  }
}

.icon_container {
  min-height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
}
.label_container {
  padding-top: 1px;
  font-size: 14px;
  font-weight: 500;
}
.value_indicator {
  width: calc(100% - 27px);
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 1;

  @media (max-width: $tablet) {
    font-size: 13px;
  }

  @media (max-width: $tablet) {
    font-size: 12px;
  }
}

.menu_wrapper {
  z-index: 1;
  user-select: none;
}

.menu_container {
  border-radius: 8px;
  overflow: hidden;
}

.inner_container {
  width: 100%;
  border-radius: 8px;
  padding: 5px 2px 2px 5px;
  box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.1);
}

.options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 400px;
  max-width: 100%;
  overflow: scroll;
  border-radius: 8px;
  gap: 5px;
}

.option_container {
  font-size: 14px;
  color: #141414;
  line-height: 150%;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
  border-radius: 8px;
  color: #5c668c;
  &.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
  &:hover {
    background-color: #dceefe;
    color: #141414;
  }

  .description {
    color: #5e7285;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
  }

  .right_col {
    display: flex;
    flex-direction: column;
    > b {
      color: #141414;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;

      @media (max-width: $tablet) {
        font-size: 13px;
      }

      @media (max-width: $tablet) {
        font-size: 12px;
      }
    }
  }

  .option_name {
    gap: 8px;
    display: flex;
    align-items: center;
    color: #141414;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }

  .form_control {
    pointer-events: none;
    &:global(.MuiFormControlLabel-root) {
      margin-left: 0px;
      margin-right: 0px;
      gap: 6px;
    }
  }
  label {
    max-width: 100%;
  }

  :global(.MuiTypography-root) {
    line-height: 1.5;
    font-size: 14px;
    color: #5c668c;
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 2px;
  }
  &:hover {
    :global(.MuiTypography-root) {
      color: #141414;
    }
  }
}

.grow {
  transform-origin: 'left bottom';
  &[data-bottom-start='true'] {
    transform-origin: 'left top';
  }
}
