html {
  scroll-behavior: smooth;
  font-size: 14px;
}

ul {
  list-style: none;
}

a {
  display: block;
  font-style: none;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
ul,
p {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}
