.bullet_point {
  height: 3.5px;
  width: 3.5px;
  background-color: black;
  border-radius: 50%;
}

.additional_comment {
  background-color: #f1f5f9d9;
  padding: 9.5px 12px;
  border-radius: 12px;
  color: #141414;
  line-height: 21px;
  width: 100%;
  overflow: hidden;
  word-wrap: break-word;
}

.sub_heading {
  color: #141414;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.empty_state {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #141414;
}

.tasks_reopen_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding-left: 8px;
}

.tasks_item {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #141414;
}
