@import '../../styles/mixins/flex.scss';

.body_container {
  @include flex(column);
  gap: 24px;
}

.checkboxes {
  @include flex(column);
  gap: 8px;
}
