.wrapper {
  position: relative;
}

.dropdown {
  padding: 8px 0 8px 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: #000000;
  background-color: white;
  position: absolute;
  left: 4px;
  right: 4px;
  top: 44px;
  z-index: 2;
  opacity: 1;
  border-radius: 8px;
  transition: max-height 0.3s, opacity 0.3s;
  max-height: 310px;
  pointer-events: all;
  overflow-y: hidden;
  box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.1);
  &[data-hide='true'] {
    opacity: 0;
    pointer-events: none;
  }
}

.typed_value {
  height: 29px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding-bottom: 8px;
  border-bottom: 1px solid #f5f5f5;
  > p span {
    background: #c2d3ff;
  }
}

.finded_items_wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 295px;
  max-width: 400px;
}

.finded_items {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.list_item {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 6px;
  margin: 4px 10px 0 0;
  padding-bottom: 5px;
  max-width: 100%;
  transition: color 0.3s;
  color: #5c668c;
  border-bottom: 1px solid #e7edf1;
  &:hover {
    color: #141414;
    cursor: pointer;
  }
}

.list_item:last-child {
  border-bottom: unset;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.row {
  display: flex;
  gap: 2px;
  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    line-height: 150%;
    span {
      background: #c2d3ff;
    }
  }
}

.icon {
  padding-top: 2px;
  min-width: 17px;
  display: flex;
  align-items: center;
  > svg {
    path {
      stroke: #141414;
    }
  }
}

.spinner {
  height: 53px;
  padding-right: 8px;
}

.no_results {
  padding-right: 8px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #5a5a5a;
  font-size: 14px;
}
