@import '../../../../styles/vars.scss';

.container {
  display: flex;
  margin-bottom: 4px;
  max-height: 37px;
  margin-top: 6px;
  color: #5e7285;
  margin-left: -30px;
  top: 0px;
  padding: 8px 8px 8px 30px;
  width: max-content;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 0px 0px 8px 8px;
  transition: max-width 400ms, box-shadow 150ms;

  &[data-in-position='true'] {
    z-index: 1;
    background-color: white;
    border: 1px solid #edebeb;
    box-shadow: 2px 5px 32px 0px rgba(0, 0, 0, 0.04);
  }

  @media (max-width: $tablet-max) {
    top: 55px;
  }
}

.select_button {
  font-weight: 500;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    color: #437bff;
  }
}

.checkbox {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s, transform 0.15s;
  transform: translateX(5px);
  transition-duration: 0.15s !important;

  &[data-show-checkbox='true'] {
    pointer-events: all;
    opacity: 1;
    transform: translateX(0);
  }
}

.left_col {
  display: flex;
  width: 200px;
  flex-shrink: 0;
}

.actions_container {
  position: relative;
  display: flex;
  align-items: center;
  pointer-events: none;
  gap: 8px;
}

.form_control {
  gap: 8px;
  margin-right: 0px;
  user-select: none;
  align-items: center;
  position: relative;
  left: -13px;
  display: flex;
}

.skeleton {
  margin-top: 14px;
  margin-bottom: 12px;
  height: 21px;
  transform: unset;
}
