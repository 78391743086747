.progress {
  &:global(.MuiLinearProgress-root) {
    height: 7px;
    border-radius: 12px;
    background: transparent;
    border: 1px solid #878b92;
  }

  &.default {
    border: 1px solid #878b92;
    background: transparent;
  }

  &.green {
    border: 1px solid #c3df50;

    :global(.MuiLinearProgress-bar) {
      background-color: #c3df50;
    }
  }

  &.blue {
    border: 1px solid #7894ff;

    :global(.MuiLinearProgress-bar) {
      background-color: #7894ff;
    }
  }

  &.primary_blue {
    border: 1px solid #0851fe;

    :global(.MuiLinearProgress-bar) {
      border-radius: 8px;
      background-color: #0851fe;
    }
  }

  &.light_blue {
    border: 1px solid #6cb9f0;

    :global(.MuiLinearProgress-bar) {
      background-color: #6cb9f0;
    }
  }

  &.red {
    border: 1px solid #ff6464;

    :global(.MuiLinearProgress-bar) {
      background-color: #ff6464;
    }
  }

  &[data-tooltip='true'] {
    cursor: pointer;
  }
}
