.input {
  font-size: 16px;
  :global(.MuiSelect-icon) {
    opacity: 1;
    transition: opacity 0.15s, transform 0.3s;
  }
  &[data-hide-menu-icon='true'] {
    :global(.MuiSelect-icon) {
      opacity: 0;
    }
  }

  svg {
    width: 1.3rem;
    height: 1.3rem;
    top: calc(50% - 0.65rem);
    fill: #4d4d4d;
  }
}

.placeholder {
  display: flex;
  align-items: center;
  opacity: 0.8;
  min-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #9a9b9c;
  font-weight: 500;
  font-size: 16px;

  &_small {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #9a9b9c;
  }
}

.disabled {
  opacity: 0.5;
}

.menu {
  :global(.MuiMenuItem-root) {
    background-color: transparent;
    color: #5a5a5a;
    &:hover {
      color: #141414;
    }
  }
  :global(.Mui-selected) {
    background-color: transparent;
    color: '#D0D0D0' !important;
    &:hover {
      background-color: 'transparent';
    }
  }
}
