.checkboxes_container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 10px;
}

.row {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  margin: 0;
}

.list_item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
}

.status_row {
  display: inline-flex;
  flex-direction: row;
}

.confirm_message {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #141414;
}

.name_container {
  color: #141414;
}

.warning {
  display: flex;
  padding: 16px 12px;
  gap: 6px;
  border-radius: 8px;
  background: #ffe7d2;
  > p {
    color: #f39e03;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
  }
}

.warning_icon {
  margin-top: 2px;
}

.list {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 250px;
}
