.row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
  flex-grow: 1;
}
.white_container_mode {
  .component_container {
    background-color: white;
  }
}
.component_container {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgb(224, 224, 224);
  gap: 16px;
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 18px;
  font-weight: 500;
}
.subtitle {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 7px;
}
