@import '../../../../styles/mixins/flex.scss';

.container {
  flex: 1;
  @include flex(column, center, center);
  gap: 12px;

  h3 {
    font-size: 34px;
    text-align: center;
    margin-bottom: 8px;
  }

  p {
    max-width: 470px;
    text-align: center;
    font-size: 14px;
  }
}

.wrapper {
  @include flex(column, center);
  gap: 16px;
  max-width: 480px;
}

.navigation_link {
  color: #0851fe;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
}
