.checkbox {
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: flex-start;
  min-width: 20px;
  transform: translate(5px, -50%);
  transition: opacity 0.15s, transform 0.15s;
  opacity: 0;
  pointer-events: none;

  &[data-is-checkbox-visible='true'] {
    pointer-events: all;
    opacity: 1;
    transform: translate(0, -50%);
  }
}
