.spinner_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #141414;

  .spinner {
    margin-top: 12px;
    position: relative;
    left: unset;
    top: unset;
    transform: none;
  }
}

.modal {
  width: 100%;
  max-width: 1350px !important;
}
.body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 220px);
  max-height: 650px;
  width: 100%;
  background-color: #eef0f2;
}
.footer {
  justify-content: flex-end;
}
