.row {
  line-height: 21px;
  padding: 4px 6px;
  border-radius: 6px;
  span {
    background: #c2d3ff;
  }
  &[data-is-clickable='true'] {
    cursor: pointer;
    &:hover {
      background-color: #f1f5f9;
    }
  }
}
