.modal {
  max-width: min-content;
}

.modal_header {
  display: flex;
  justify-content: flex-end;
  background-color: #fbfbfb;
}

.container {
  font-size: 39px;
  padding: 0.8em;
  width: 1200px;
  color: #141414;
  background-color: #fff;
  max-height: 825px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8em;
  font-size: 0.9em;
  font-style: normal;
  font-weight: 500;

  span {
    font-weight: 700;
  }
}

.production_details {
  margin-bottom: 1.1em;
  max-width: 100%;
}

.product_name {
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  margin-bottom: 0.2em;
}

.configuration {
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  margin-bottom: 0.2em;
}

.variant {
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.row {
  display: flex;
  gap: 1em;
}

.col_right {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 0.25em;
}

.order_details_row {
  font-size: 0.8em;
  font-style: normal;
  font-weight: 500;
}

.value {
  font-size: 1.1em;
  font-weight: 700;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
