.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0px;
  right: 0px;
  margin: 15px;
  z-index: 50;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 2px 5px 32px 0px rgba(0, 0, 0, 0.1);
}

.minimap {
  position: relative !important;
  height: 150px;
  margin: 0px !important;
}

.scale_controls {
  position: relative !important;
  display: flex;
  justify-content: center;
  margin: 0px !important;
  box-shadow: none !important;

  & :nth-child(1) {
    order: 2;
  }
  & :nth-child(2) {
    order: 3;
  }
  & :nth-child(3) {
    order: 4;
  }
  & :nth-child(4) {
    order: 1;
  }

  button {
    height: 40px;
    width: 25%;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background-color: #f4f4f4;
    }

    svg {
      height: 16px;
      width: 16px;
      max-height: unset;
      max-width: unset;

      path {
        stroke-width: 1;
        fill: #878b92;
      }
    }
  }
}
