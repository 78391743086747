.container {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
  gap: 16px;
}

.button {
  height: 41px;
}

.breadcrumbs {
  display: flex;
  margin-bottom: 20px;
}
