@mixin section {
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .close_action {
    display: flex;
    align-items: center;
    svg {
      transition: all 150ms ease-in-out;
      cursor: pointer;
    }

    &:hover {
      svg {
        transform: scale(1.1);
      }
    }
  }

  .close_action svg {
    fill: #535353;
  }
}

.section {
  @include section();
}

.section_without_shadow {
  @include section();
  box-shadow: none;
}

.with_border_bottom {
  border-bottom: 1px solid #eff5f9;
}

.withSubtitle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.subtitle {
  font-size: 14px;
  margin-bottom: 4px;
}

.centerModeWithOutTitile {
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  padding: 6px;
  border-radius: 4px !important;
  margin-right: -5px;
  margin-top: -4px;
}
