.container {
  width: 100%;
  overflow: hidden;
}

.menu_container {
  padding: 0 8px 0 4px;
  max-width: 179px;
  > ul {
    padding: 0;
  }
}

.select {
  height: 100%;
  width: 100%;
  > div {
    padding: 4px !important;
  }
}

.value {
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 8px;
}

.option {
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 8px;
  padding: 4px 0px;
  cursor: pointer;
  background-color: #fff !important;
  border-radius: unset !important;
  &:not(:last-child) {
    border-bottom: 1px solid #e2eef7;
  }

  &:hover {
    .company_name {
      color: #0851fe;
    }
  }
}

.check_icon_container {
  display: flex;
  align-items: center;
  min-width: 16px;
  min-height: 16px;
}

.check_icon {
  width: 16px;
  height: 16px;
}

.logo {
  border-radius: 6px !important;
  flex-shrink: 0;
}

.company_name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex-grow: 1;
  color: #141414;
  font-weight: 500;
  transition: color 0.2s;
  transition-duration: 200ms !important;
}
