.red {
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 4px;
  color: #f44;
  border-radius: 8px;
  border: 1px solid #f44;
  font-family: Montserrat, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;

  svg {
    width: 14px;
    height: 14px;
  }
}

.red:hover {
  color: #fff;
  background: #ff7d7d;
  svg * {
    stroke: #fff;
  }
}

.tooltip {
  z-index: 3000;
}
