.row {
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
}

.breadcrumb_link {
  display: flex;
  gap: 4px;
  flex-direction: row;
  align-items: center;

  &:hover {
    .copy_icon_container {
      opacity: 1;
      max-width: 19px;
    }
  }

  .icon {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 2px;

    &.active {
      path {
        stroke: #0851fe;
      }
    }
  }
}
