@import '../../../../styles/mixins/flex.scss';

.password_wrapper {
  @include flex(column);
}

.forgot_password {
  margin-top: 12px;
  align-self: flex-end;
  width: fit-content;
  color: #0851fe;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
