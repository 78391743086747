.menu {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #ffffff;
  box-shadow: 2px 5px 32px 0px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(30px);
  border-radius: 12px;
  width: 265px;
  position: absolute;
  z-index: 10;
  top: 54px;
  right: 52px;
  opacity: 0;
  padding: 16px;
  cursor: default;
  pointer-events: none;
  transition: opacity 0.2s, transform 0.2s ease-in-out;
  transform: translateY(-13px);

  &[data-is-menu-open='true'] {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0px);
  }
}

.backdrop {
  display: none;

  &[data-is-menu-open='true'] {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
  }
}

.user {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  gap: 12px;
  padding-bottom: 16px;
  border-bottom: 0.5px solid #ebeef0;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: 100%;
  overflow: hidden;
}

.bold {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
}

.email {
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  color: #5e7285;
}

.item {
  cursor: pointer;
  transition: color 0.2s;
  &:hover {
    color: #0851fe;
  }
}
