.bullet_point {
  height: 3.5px;
  width: 3.5px;
  background-color: #7894ff;
  border-radius: 50%;
}

.bullet_point_secondary {
  @extend .bullet_point;
  background-color: #141414;
}

.link {
  color: #7894ff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.textarea {
  padding: 10px 12px;
  outline: none;
  resize: none;
  border: 1.2px solid #bcc4e3;
  border-radius: 8px;

  &:focus {
    border: 1.2px solid #7894ff;
  }

  &::placeholder {
    font-weight: 500;
    color: #878b92;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }
}

.divider {
  height: 0.5px;
  background-color: #eaeaee;
  width: 100%;
}

.tasks_heading {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #141414;
}

.tasks_reopen_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding-left: 8px;
}

.tasks_item {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #141414;
}

.comment_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
