.task_container {
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
  width: 280px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 12px;
  transition: 200ms;
  cursor: pointer;
  color: initial;
  box-shadow: 2px 5px 32px rgba(0, 0, 0, 0.06);

  &:hover {
    border-color: #0851fe;
  }
}

.text {
  padding: 8.5px 14px;
  font-size: 14px;
  max-width: 100%;
}

.handle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px !important;
  width: 16px !important;
  background-color: #e4e7e9 !important;
  border: 1px solid #878b92 !important;

  &_hide {
    opacity: 0;
  }
}

.handle_left {
  margin-left: -5px;
}

.handle_right {
  margin-right: -5px;
}

.to_do,
.reopened {
  border-color: #8ab6e0;
}

.blocked,
.stopped {
  border-color: #fc7070;
}

.in_progress,
.on_hold {
  border-color: #7894ff;
}

.done,
.from_stock,
.canceled {
  border-color: #a6c919;
}
