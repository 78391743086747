.autosuggest_section {
  position: absolute;
  left: 0;
  top: 30px;
  width: 50%;
  opacity: 0;
  pointer-events: none;
  padding: 4px 0 4px 4px;
  max-height: 500px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  z-index: 900;
  box-shadow: 2px 5px 32px 0px rgba(0, 0, 0, 0.06);
  transition: transform 200ms, opacity 200ms;
  transform: translateY(-5px);
  &::after {
    opacity: 0;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.8s;
    border-bottom: 3px solid #7894ff;
  }
  &[data-is-lazy-processing='true'] {
    &::after {
      opacity: 0.3;
      animation: breathe 0.8s infinite alternate;
    }
  }
  &[data-is-full-width='true'] {
    width: 100%;
  }
  &[data-is-visible='true'] {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0px);
  }
}

.no_results {
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5c668c;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.spinner {
  height: 29px;
  max-height: 29px;
}

.items {
  overflow: scroll;
  max-height: 205px;
}

@keyframes breathe {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
