.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel {
  width: 388px;
  padding: 32px;
  box-shadow: 2px 5px 32px 0px rgba(0, 0, 0, 0.06);
  background-color: white;
  border-radius: 16px;
  &[data-with-url='true'] {
    width: 500px;
  }
}

.title {
  color: #141414;
  font-size: 24px;
  font-weight: 600;
  line-height: 100%;
  margin-top: 32px;
  margin-bottom: 16px;
}

.subtitle {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.url {
  margin-top: 30px;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  &:hover {
    color: blue;
  }
}

.back_button {
  margin-top: 24px;
  width: 100%;
}
