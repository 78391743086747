.container {
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
}
