.product_container {
  padding: 20px 40px 110px 40px;
}

.tag {
  padding: 12px;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.activate_product_container {
  display: flex;
  flex-direction: column;
  .text {
    font-weight: 400;
    font-size: 14px;
  }
  .related_product {
    cursor: pointer;
    color: #141414;
    font-weight: 700;
    text-decoration: underline;
    &:hover {
      color: #0851fe;
    }
  }
}
