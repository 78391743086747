@import '../../styles/mixins/flex.scss';
@import '../../styles/vars.scss';
.form {
  @include flex(column);
  gap: 24px;
  width: 100%;

  h3 {
    font-size: 28px;
  }
}

.success_wrapper {
  @include flex(column, center, center);
  z-index: 1;
  gap: 20px;
  width: 100%;

  h3 {
    max-width: 350px;
    text-align: center;
  }
}

.success_wrapper_sign_up {
  @include flex(column, center, center);
  z-index: 1;
  gap: 20px;
  width: 100%;

  h3 {
    max-width: 350px;
    text-align: center;
  }
}

.intro {
  @include flex(column);
  gap: 6px;
}

.fields {
  @include flex(column);
  gap: 24px;
}

.emoji {
  @include flex(row, center, center);
  height: 60px;
  width: 60px;
  text-align: center;
  background-color: #bcf4de;
  font-size: 32px;
  border-radius: 50%;
}

.heading {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
}

.button {
  width: 100%;
  padding: 16px !important;
  font-size: 14px !important;
  padding: 10px 16px 10px 16px !important;
  min-height: 45px !important;
}

.sign_up_in {
  @include flex(row, flex-start);
  gap: 6px;
  font-size: 14px;
  margin-bottom: 40px;

  a {
    color: #0851fe;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.sign_up_log_in {
  @include flex(row, center);
  gap: 6px;
  font-size: 14px;
  margin-bottom: 40px;

  a {
    color: #0851fe;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: $tablet-max) {
  .sign_up_in {
    display: none;
  }

  .success_wrapper_sign_up {
    border: 1px solid #eaeaee;
    border-radius: 16px;
    padding: 16px;
    box-shadow: 1px 1px 1px 0px #d1d1d217;
    h3 {
      max-width: 450px;
      text-align: center;
    }
  }

  .success_wrapper {
    border: 1px solid #437bff;
    border-radius: 16px;
  }
}

.sign_up_in_mobile {
  @extend .sign_up_in;
  display: none;
}

@media (max-width: $tablet-max) {
  .sign_up_in_mobile {
    @include flex(row, center);
    flex: 7;
  }
}

.sign_up_in_arrow_text {
  color: #0851fe;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}
