.select {
  & fieldset {
    border-width: 1px;
  }

  div {
    font-weight: 400 !important;
  }
}

.menu_container {
  margin-top: 6px;
  padding: 4px;
}

.menu_item {
  cursor: pointer;

  &:hover {
    background-color: #f1f5f9;
  }

  &[data-hidden='true'] {
    display: none;
  }
}
