.modal {
  max-width: fit-content;
  padding: 0px 0px 16px 0px;
}

.modal_header {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  background-color: #fff;
}

.modal_header_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.container {
  background-color: #fff;
  overflow: hidden;
}

.qr {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  margin-right: -21px;
}

.qr_button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 8px;
  padding: 8px 12px;
  background-color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border: 1px solid #bcc4e3;
  border-radius: 8px;
  color: #141414;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    border-color: #7894ff;
  }
}

.active {
  border-color: #0851fe;
}

.mobile_qr {
  padding: 0px !important;
}
