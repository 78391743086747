html {
  overflow: auto;

  &:has(*[data-is-mobile-menu-opened='true']) {
    overflow: hidden;
  }
  &:has(*[data-is-filters-modal-opened='true']) {
    overflow: hidden;
  }
}

.container {
  display: flex;

  .content_wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .content_with_sidebar {
    display: flex;
  }

  .content {
    flex: 1;
  }
}

.sidebar {
  position: sticky;
  top: 0;
  z-index: 30;
}
