@import '../../styles/vars.scss';

.item_container {
  width: 100%;
  .icon_with_title {
    display: flex;
    align-items: center;
    gap: 8px;
    @media (max-width: $tablet) {
      font-size: 13px;
      gap: 6px;
    }
    @media (max-width: $mobile) {
      font-size: 12px;
    }
  }

  .text {
    gap: unset;
  }

  .avatar {
    height: 24px;
    width: 24px;
    font-size: 12px;
    margin-right: 4px;
  }

  .icon_with_chip {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .chip_with_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .chip {
    height: 26px;
  }

  .button {
    min-width: 26px;
    padding: 5px !important;
  }

  .icon {
    width: 16px;
    height: 16px;
    svg {
      width: 16px;
      height: 16px;
    }
    @media (max-width: $tablet) {
      width: 12px;
      svg {
        width: 12px;
      }
    }
  }

  .title {
    color: #5a5a5a;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    @media (max-width: $tablet) {
      font-size: 13px;
    }
    @media (max-width: $mobile) {
      font-size: 12px;
    }
  }

  .breadcrumbs_container {
    line-height: 100%;
  }

  .breadcrumbs {
    color: #878b92;
    margin-left: 24px;
    @media (max-width: $tablet) {
      margin-left: 18px;
    }
  }

  .icon_with_chip {
    & .button {
      opacity: 0;
    }
  }

  &:hover {
    .title {
      color: #141414;
    }
    .breadcrumbs {
      color: #141414;
    }
    .icon {
      svg * {
        stroke: #141414;
      }
    }
    .icon_with_chip {
      & .button {
        opacity: 1;
      }
    }
  }
}

.label > p {
  @media (max-width: $tablet) {
    font-size: 13px;
  }
}

.input,
.input > div {
  @media (max-width: $tablet) {
    height: 32px;
  }
}

.input > div > input {
  @media (max-width: $tablet) {
    font-size: 13px !important;
  }
}

.start_icon {
  width: 30px;
  margin-right: 0;
}

.new_option_container {
  cursor: pointer;
}

.popper {
  border-radius: 8px;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.12);
}

.paper {
  margin-bottom: 0;
  transition: border-bottom 0.3s;
  border-bottom: 3px solid rgba(120, 148, 255, 0);
  // temporary fix for gray text color
  ul li {
    min-height: unset !important;
    color: #5c668c !important;
    padding: 6px 10px !important;
    @media (max-width: $tablet) {
      padding: 4px 8px !important;
    }
    &:hover {
      color: #141414 !important;
    }
  }
}

.paper_loading {
  border-bottom-color: rgba(120, 148, 255, 1);
  animation: breathe 0.8s infinite alternate;
}

@keyframes breathe {
  from {
    border-bottom-color: rgba(120, 148, 255, 0.3);
  }
  to {
    border-bottom-color: rgba(120, 148, 255, 1);
  }
}

.popper {
  z-index: 2000;
}
