.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #bcc4e3;
  padding: 7.5px 12px;
  height: fit-content;
  line-height: 1;
  cursor: pointer;
  gap: 8px;
  transition: background-color 0.15s;
  &[data-selected='true'] {
    background-color: #dae3ff;
  }
  &[data-disbled='true'] {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }
  .chevron_icon {
    transform: rotate(90deg);
    transition: transform 0.1s;
    &.open {
      transform: rotate(-90deg);
    }
  }

  &:hover {
    border-color: #0851fe;
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
  }
}

.icon_container {
  display: flex;
  align-items: center;
}
.label_container {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  padding-top: 1px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #878b92;
}
.label_item {
  font-size: 14px;
  color: #000000;
  line-height: 21px;
}
.value_indicator {
  padding: 6px 6px;
  background-color: #d5e4f2;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1;
}

.menu_container {
  border-radius: 8px;
  box-shadow: 2px 5px 32px 0px #0000000f;
  overflow: hidden;
  background-color: #ffffff;
  width: 100%;
  min-width: 449px;
  margin-top: 6px;
}
.options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 400px;
  max-width: 100%;
  overflow: scroll;
  gap: 6px;
  padding: 8px;
}

.option_container {
  font-size: 14px;
  color: #5c668c;
  line-height: 150%;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  .form_control {
    pointer-events: none;
    color: #5e7285;
    &:global(.MuiFormControlLabel-root) {
      margin-left: 0px;
      margin-right: 0px;
      gap: 6px;
    }
  }
  label {
    max-width: 100%;
  }

  :global(.MuiTypography-root) {
    line-height: 1.5;
    font-size: 14px;
    color: #5c668c;
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 2px;
  }
  &:hover {
    :global(.MuiTypography-root) {
      color: #141414;
    }
  }
}

.menu_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: #141414;
  padding: 8px 8px 7px 8px;
  border-bottom: 1px solid #ebeef0;
}

.header_container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 8px 0 8px 0;
  margin: 0 8px 0 8px;
  border-bottom: 1px solid #ebeef0;
}
