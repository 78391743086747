.controls_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  padding: 2px 0px;
  &[data-align-right='true'] {
    justify-content: flex-end;
  }

  label {
    gap: 4px;
    margin: 0px;
  }
}

.avatar_container {
  width: 40px;
}

.disable_events {
  pointer-events: none;
}

.total_disable {
  pointer-events: none;
  opacity: 0.4;
}

.context_area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  padding: 0px 30px;
  transition: 200ms;
  cursor: auto;

  &[data-reopened='true'] {
    padding: 0px;
  }
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  padding: 2px 6px;
  border-radius: 8px;
  transition: 200ms;

  &.new {
    border: 1.2px solid #3ed296;
    color: #3ed296;

    svg * {
      stroke: unset;
    }
  }
}

.warning_wrapper {
  cursor: pointer;
  &:hover {
    .badge {
      background-color: #ffe3d9;
      border: 1px solid transparent;
    }
  }
}

.position_badge_wrapper {
  cursor: pointer;
  &:hover {
    .badge {
      background-color: #d0dcfa;
      border: 1px solid transparent;
    }
  }
}

.progress {
  display: flex;
  align-items: center;
  gap: 4px;

  > span {
    width: 77px;
  }
}

.status_tag {
  height: 30px;
}

.hide_tooltip {
  display: none !important;

  & > div {
    display: none !important;
  }
}

.lock_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background-color: #9eadc1;
  border-radius: 8px;
  opacity: 0;
  border: none;
  // scale is needed to prevent hovering over lock icon
  transform: scale(0);
  transition: opacity 200ms ease-in-out, transform 0s ease-in-out 0.5s;

  &[data-show='true'] {
    cursor: pointer;
    opacity: 1;
    transform: scale(1);
  }

  &[data-dimmed='true'] {
    cursor: pointer;
    opacity: 0.5;
    transform: scale(1);
    transition: opacity 200ms ease-in-out, transform 0s ease-in-out;
  }

  svg {
    height: 16px;
    width: 16px;
  }
}

.tooltip {
  & > div {
    margin: 5px 0px !important;
    padding: 8px !important;
    max-width: none;
  }
}

.bullet_point {
  height: 3.5px;
  width: 3.5px;
  border-radius: 50%;
  background-color: black;
}
