.container {
  display: flex;
  border-radius: 12px;
  background: #fff;
  box-shadow: 2px 5px 32px 0px rgba(0, 0, 0, 0.06);
  padding: 12px;
  cursor: pointer;
  border: 1px solid transparent;
  width: 100%;
  transform: scale(1);
  transition: border 100ms ease-in-out, box-shadow 0.35s;
  &[data-highlighted='true'] {
    box-shadow: 0px 0px 10px 2px rgb(6 80 254 / 28%);
    border: 1px solid #0851fe;
  }

  * {
    display: flex;
    align-items: center;
  }

  &:hover {
    border-color: #0851fe;
  }
}

.grid {
  width: 100%;
  gap: 8px;
  display: grid;
  grid-template-areas: 'icon position user actions';
  grid-template-columns: 30px auto 200px 34px;
}

.icon {
  height: 30px;
  width: 30px;
  padding: 5px;
  border-radius: 8px;
  background: #ebeef0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.department_name {
  color: #211d2b;
  font-size: 14px;
  font-weight: 600;
}
.positions_count {
  display: flex;
  gap: 4px;
  justify-content: flex-end;
}
.margin_right {
  margin-right: 4px;
}

.checkbox {
  padding: 0 10px 0 2px;
  display: flex;
}

.actions {
  transition: opacity 0.3s;
  &[data-hidden='true'] {
    opacity: 0;
    pointer-events: none;
  }
}
