.container {
  padding: 20px 40px 110px 40px;
}

.skeleton {
  span {
    transform: unset;
  }
}

.warning_banner {
  display: flex;
  align-items: center;
  padding: 23px 12px;
  border-radius: 8px;
  background: #fcdcc0;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #df8839;
}
