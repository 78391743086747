.badge {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 20px;
  padding: 0 4px;
  font-size: 14px;
  font-weight: 400;
  margin-top: -2px;
  border-radius: 4px;
}

.red {
  color: #ff6464;
  background: #ff64641a;
}

.gray {
  color: #878b92;
  background: #ebeef0;
}

.white {
  color: #878b92;
  background: #fff;
}
