.container {
  min-width: 500px;
  max-width: 100%;
}

.body {
  font-size: 14px;
  line-height: 150%;
}

.padding_bottom {
  padding: 0 24px 24px 24px !important;
}
