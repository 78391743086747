@import '../../../../../../styles/vars.scss';

@mixin active_link($padding, $show_selected, $pointer_events) {
  padding: $padding;
  height: 40px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  pointer-events: $pointer_events;
  transition: padding 0.2s ease-in-out;
  gap: 12px;
  &::before {
    content: '';
    transition: opacity 0.2s;
    opacity: $show_selected;
    width: 4px;
    height: 40px;
    background: #0851fe;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
  }
  svg path,
  svg rect,
  svg circle {
    stroke: #0851fe;
  }
  .text {
    color: #0851fe;
  }
}

@mixin link($padding, $pointer_events) {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  height: 40px;
  pointer-events: $pointer_events;
  transition: padding 0.2s ease-in-out;
  gap: 12px;
  padding: $padding;
  &::after {
    content: '';
    transition: transform 0.15s ease-in-out;
    width: 4px;
    height: 40px;
    background: #0851fe;
    border-radius: 2px;
    position: absolute;
    transform: translateX(-4px);
    top: 0;
    left: 0;
  }
  &:hover {
    &::after {
      transform: translateX(0px);
    }
    svg path,
    svg rect,
    svg circle {
      stroke: #0851fe;
    }

    .text {
      color: #0851fe;
    }
  }
}

.link {
  @include link(8px 30px, all);

  @media (max-width: $tablet) {
    @include link(6px 16px, all);
  }
}

.link_collapsed {
  @include link(8px 43px, none);

  @media (max-width: $tablet) {
    @include link(6px 16px, none);
  }
}

.active_link {
  @include active_link(8px 30px, 1, all);

  @media (max-width: $tablet) {
    @include active_link(6px 16px, 1, all);
  }
}

.active_link_collapsed {
  @include active_link(8px 43px, 0, none);

  @media (max-width: $tablet) {
    @include active_link(6px 16px, 0, none);
  }
}

.icon {
  line-height: 0;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  svg * {
    stroke: #141414;
    transition: stroke 0.15s ease-in-out;
  }
}

.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #878b92;
  z-index: 5;
  transition: color 0.15s ease-in-out;
  white-space: nowrap;
  visibility: visible;
  opacity: 1;
  width: 149px;
}

.animation_wrapper {
  justify-content: flex-start;
}

.container {
  overflow: hidden;
}
