.modal {
  width: 500px;
  max-width: 100%;
  border-radius: 8px;

  .modal_header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      margin-left: 16px;
      flex: 1;
    }
  }

  .header {
    display: grid;
    gap: 8px;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
