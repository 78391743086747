.autocomplete {
  :global(.MuiAutocomplete-endAdornment) {
    top: unset;
    svg {
      fill: #4d4d4d;
      width: 1.3rem;
      height: 1.3rem;
    }
  }
}

.text_highlight {
  border-radius: 2px;
  background: #c2d3ff;
}

.title {
  color: #5a5a5a;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}
