.breadcrumb_link {
  display: flex;
  gap: 4px;
  flex-direction: row;
  align-items: center;
  &[data-is-clickable='true'] {
    border-bottom: 1px solid transparent;
  }
  &[data-is-clickable='false'] {
    cursor: default;
  }

  &:hover {
    .copy_icon_container {
      opacity: 1;
      max-width: 19px;
    }
  }

  .text {
    font-size: 12px;
    color: #878b92;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }

  .additional_content {
    display: flex;
    align-items: center;
    height: 24px;
    overflow-y: hidden;
  }

  .inner_container {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .copy_icon_container {
    padding-top: 5px;
    max-width: 0;
    transition: opacity 0.4s, max-width 0.4s;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .copy_icon {
    min-width: 15px;
    min-height: 15px;
    path {
      stroke: #878b92;
    }
  }

  .copy_icon_blue {
    min-width: 15px;
    min-height: 15px;
    path {
      stroke: #0851fe;
    }
  }

  .done_icon {
    min-width: 15px;
    min-height: 15px;
    > circle {
      stroke: #3ed296;
    }
    path {
      stroke: #3ed296;
    }
  }

  .text_with_icon {
    display: flex;
    align-items: center;
    padding-top: 2px;
    gap: 4px;
    max-width: 100%;
    border-bottom: 1px solid transparent;
    transition: border-bottom-color 0.5s;
    &[data-is-deleted='true'] {
      text-decoration: line-through;
    }
  }

  .icon {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 2px;

    &.active {
      path {
        stroke: #0851fe;
      }
    }
    &.ordinary {
      path {
        stroke: #878b92;
      }
    }
  }

  .ordinary {
    color: #878b92;
    &:hover[data-is-clickable='true'] {
      border-bottom-color: #878b92;
    }
  }

  .active {
    color: #0851fe;
    &:hover[data-is-clickable='true'] {
      border-bottom-color: #0851fe;
    }
  }
}
