// Mixins
@mixin base($border_icon, $text, $background: transparent) {
  background-color: $background;
  border-color: $border_icon;
  transition: background-color 0.2s, border-color 0.2s;
  svg * {
    stroke: $border_icon;
  }
  .text {
    &,
    * {
      color: $text;
    }
  }
}
@mixin hover($background, $icon, $text) {
  &.pressable {
    cursor: pointer;
    &:hover {
      border-color: $background;
      background-color: $background;
      svg * {
        stroke: $icon;
      }
      .text {
        &,
        * {
          color: $text;
        }
      }
    }
  }
}

@mixin active($background, $content) {
  &.active {
    border-color: $background;
    background-color: $background;
    svg * {
      stroke: $content;
    }
    .text {
      &,
      * {
        color: $content;
      }
    }
  }
}
@mixin disabled {
  &.disabled {
    pointer-events: none;
    border-color: #ced1d4;
    background-color: transparent;
    svg * {
      stroke: #ced1d4;
    }
    .text {
      &,
      * {
        color: #ced1d4;
      }
    }
  }
}

// Main
.container {
  padding: 4px 12px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #878b92;
  color: #878b92;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  gap: 4px;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text_ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  &.grey {
    @include base(#878b92, #7e848c);
    @include hover(#dee1e5, #878b92, #7e848c);
    @include active(#878b92, #ffffff);
    @include disabled();
  }
  &.grey_dark_text {
    @include base(#000000, #000000);
    @include hover(#dee1e5, #000000, #000000);
    @include active(#ced1d4, #000000);
    @include disabled();
  }
  &.grey_dark_filled {
    @include base(#000000, #000000, #dee1e5);
    border-color: #dee1e5;
    @include hover(#ced1d4, #000000, #000000);
    @include active(#000000, #ffffff);
    @include disabled();
  }
  &.blue {
    @include base(#6cb9f0, #419ad9);
    @include hover(#d5edff, #6cb9f0, #419ad9);
    @include active(#6cb9f0, #ffffff);
    @include disabled();
  }
  &.violet {
    @include base(#d9dff7, #93a6f5);
    @include hover(#ebeefd, #d9dff7, #93a6f5);
    @include active(#d9dff7, #000000);
    @include disabled();
  }
  &.green {
    @include base(#3ed296, #2bab77);
    @include hover(#bcf4de, #3ed296, #2bab77);
    @include active(#3ed296, #ffffff);
    @include disabled();
  }
  &.yellow {
    @include base(#ffcd1e, #e4b81a);
    @include hover(#fff3ca, #ffcd1e, #f0c21c);
    @include active(#ffcd1e, #ffffff);
    @include disabled();
  }
  &.orange {
    @include base(#f8bc86, #df8839);
    @include hover(#ffe5cd, #f8bc86, #df8839);
    @include active(#f8bc86, #ffffff);
    @include disabled();
  }
  &.light_orange {
    @include base(#f8bc86, #df8839);
    @include hover(#ffe5cd, #f8bc86, #df8839);
    @include active(#ffead7, #df8839);
    @include disabled();
  }
  &.red {
    @include base(#ff6464, #fc3030);
    @include hover(#ffd7d7, #ff6464, #fc3030);
    @include active(#ff6464, #ffffff);
    @include disabled();
  }
  &.purple {
    @include base(#7b78ff, #6462fb);
    @include hover(#d0dcfa, #7b78ff, #6462fb);
    @include active(#7b78ff, #ffffff);
    @include disabled();
  }
}
